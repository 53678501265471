/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import Logo from "../../images/MadeTranparent 2.png";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { history } from "../../servies/services";
class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open1: false,
      value: null,
      message: "",
      inputVal: "",
      anchorEl: false,
      downArrow: true,
      user: sessionStorage.getItem("user_name"),
      poolName: sessionStorage.getItem("poolname"),
      DealType: sessionStorage.getItem("DealType"),
      OrgName: sessionStorage.getItem("OrgName") || null,
      currentUser: sessionStorage.getItem("user_name"),
      UserId: sessionStorage.getItem("userid"),
      notificationdata: [],
    };
  }

  async componentDidMount() {
    history.go(1);
    if (this.state.currentUser !== null) {
      const firstname = this.state.currentUser;
      const namefirstletter = firstname.charAt(0).toUpperCase();
      this.setState({ currentUser: namefirstletter });
    }
  }

  logoutBtn() {
    sessionStorage.clear();
    window.location.replace("/");
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-5 col-sm-12 align-self-center">
            <Link
              to={
                this.state.user.includes("Investor")
                  ? "/investor/dashboard"
                  : this.state.user.includes("Trustee")
                  ? "/dashboard"
                  : this.state.user.includes("Servicer")
                  ? "/dashboard/servicer"
                  : "/issuer/dashboard"
              }
              className="logo_img"
              title={""}
            >
              <img src={Logo} alt="hello" />
            </Link>
          </div>

          <div className="col-md-7 col-sm-12 text-right">
            <React.Fragment>
              <div className="float-right header_right">
                <React.Fragment>
                  <p className="orgname">
                    <NotificationsIcon className="notificationcolor"></NotificationsIcon>
                  </p>
                  <ul className="preprocessing_menu">
                    <li className="userInfo currentuser">
                      <Link onClick={this.logoutBtn}>
                        <p>{this.state.currentUser}</p>
                      </Link>
                    </li>
                    <li>
                      {/* <OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="bottom"
                            overlay={this.popoverBottom}
                          > */}
                      <button
                        className="userinfo-currentuser"
                        onClick={() =>
                          this.setState((prevState) => ({
                            downArrow: !prevState.downArrow,
                          }))
                        }
                      >
                        {this.state.downArrow ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ExpandLessIcon />
                        )}
                      </button>
                      {/* </OverlayTrigger> */}
                    </li>
                  </ul>
                </React.Fragment>
              </div>
            </React.Fragment>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(header);
