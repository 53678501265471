// /* eslint-disable require-jsdoc */
// import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import {
//   IssuerIcons,
//   ServicerMainDashboard,
//   Investor_DashBoard,
//   PendingUsersDashBoard,
//   Issuer_DashBoard,
// } from "./menu";
// import LinkItem from "../linkItem";
// import AIChat from "../../images/AIChat.png";
// import SupportWindow from "../SupportWindow/SupportWindow";
// import NotificationsIcon from "@material-ui/icons/Notifications";
// import AG_Inv_Icon from "../../images/AG_Inv_Icon.svg";
// import help from "../../images/help.svg";
// import "./sidebar.css";

// export default class sidebar extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentUser: sessionStorage.getItem("user_name"),
//       // currentUser: sessionStorage.getItem('userrole'),
//       OrgName: sessionStorage.getItem("OrgName"),
//       userrole: sessionStorage.getItem("userrole"),
//       ChannelName: sessionStorage.getItem("ChannelName"),
//       loading: false,
//       getLoansLoader: false,
//       open: false,
//       message: "",
//       currentmenu: [],
//       menu_loader: false,
//       title: "",
//       visible: false,
//     };
//     this.wrapperRef = React.createRef();
//     this.handleClickOutside = this.handleClickOutside.bind(this);
//   }

//   logoutBtn() {
//     sessionStorage.clear();
//     window.location.replace("/");
//   }

//   async componentDidMount() {
//     if (this.state.currentUser !== null) {
//       const firstname = this.state.currentUser;
//       const namefirstletter = firstname.charAt(0).toUpperCase();
//       this.setState({ currentUser: namefirstletter });
//     }

//     if (this.state.currentUser === "Trustee") {
//       // console.log("the user is trustee");
//       this.setState({ currentmenu: IssuerIcons, menu_loader: true });
//     } else if (this.state.currentUser === "AdminAG") {
//       // console.log("the user is trustee");
//       this.setState({ currentmenu: IssuerIcons, menu_loader: true });
//     } else if (
//       this.state.currentUser.includes("Investor") ||
//       this.state.currentUser.includes("AngeloGordon")
//     ) {
//       console.log("the user is investor");
//       this.setState({ currentmenu: Investor_DashBoard, menu_loader: true });
//     } else if (this.state.currentUser === "Issuer") {
//       console.log("the user is Issuer");
//       this.setState({ currentmenu: Issuer_DashBoard, menu_loader: true });
//     } else if (this.state.currentUser === "Issuer2") {
//       console.log("the user is Issuer2");
//       this.setState({ currentmenu: Issuer_DashBoard, menu_loader: true });
//     } else if (this.state.currentUser === "admin") {
//       console.log("the user is admin");
//       this.setState({ currentmenu: PendingUsersDashBoard, menu_loader: true });
//     } else {
//       this.setState({ currentmenu: ServicerMainDashboard, menu_loader: true });
//     }
//     document.addEventListener("mousedown", this.handleClickOutside);
//   }

//   componentWillUnmount() {
//     document.removeEventListener("mousedown", this.handleClickOutside);
//   }

//   handleClickOutside(event) {
//     if (
//       this.wrapperRef.current &&
//       !this.wrapperRef.current.contains(event.target)
//     ) {
//       this.setState({ visible: false });
//     }
//   }

//   render() {
//     // console.log(this.state.currentmenu);
//     // console.log("the active sidebar is", this.props.activeComponent);
//     return (
//       <React.Fragment>
//         {this.state.menu_loader === false ? (
//           ""
//         ) : sessionStorage.getItem("user_name").includes("Admin") ||
//           sessionStorage.getItem("user_name").includes("AngeloGordon") ? (
//           <div className="sidebar Ag_Sidebar">
//             <div>
//               <div className="Ag_SidebarIcon">
//                 <img src={AG_Inv_Icon} alt="Profile" />
//               </div>
//               <ul>
//                 {this.state.currentmenu.map((item) => {
//                   return (
//                     <li
//                       key={item.title}
//                       className={`issure-icons${
//                         item.title === "ESMA Reporting" &&
//                         sessionStorage.getItem("isESMA_Flag") === "No"
//                           ? " disabledESMA"
//                           : ""
//                       }`}
//                     >
//                       {item.title === "ESMA Reporting" &&
//                       sessionStorage.getItem("isESMA_Flag") === "No" ? (
//                         <div>
//                           {/* Render a disabledESMA version of the image */}
//                           <img
//                             alt="issuer icon"
//                             src={item.icon}
//                             className="disabledESMA"
//                           />
//                           {/* <span className="disabled-overlay"></span> */}
//                         </div>
//                       ) : (
//                         <div>
//                           <Link
//                             to={item.linkto}
//                             title={item.title}
//                             className={
//                               this.props.activeComponent === item.title &&
//                               !this.state.visible
//                                 ? "issure-icon-active"
//                                 : "issure-icon"
//                             }
//                           >
//                             {/* Render the link normally */}
//                             <img alt="issuer icon" src={item.icon} />
//                           </Link>
//                         </div>
//                       )}
//                       {item.subitems != null && (
//                         <React.Fragment>
//                           <ul>
//                             <div className="arrow-left"></div>
//                             <div className="arrow_box">
//                               {item.subitems.map((item1) => (
//                                 <li key={item1.title}>
//                                   <LinkItem
//                                     to={item1.linkto}
//                                     title={item1.title}
//                                   ></LinkItem>
//                                 </li>
//                               ))}
//                             </div>
//                           </ul>
//                         </React.Fragment>
//                       )}
//                     </li>
//                   );
//                 })}
//               </ul>

//               {this.state.currentUser.includes("Investor") ? (
//                 <div
//                   className={`AI_Icon ${
//                     this.state.visible ? "issure-icon-active" : "issure-icon"
//                   }`}
//                   ref={this.wrapperRef}
//                 >
//                   <img
//                     alt="issuer icon"
//                     src={AIChat}
//                     onClick={() =>
//                       this.setState({
//                         visible: !this.state.visible,
//                       })
//                     }
//                   />
//                   <h1
//                     className="Beta"
//                     onClick={() =>
//                       this.setState({
//                         visible: !this.state.visible,
//                       })
//                     }
//                   >
//                     BETA
//                   </h1>

//                   <SupportWindow visible={this.state.visible} />
//                 </div>
//               ) : null}
//             </div>

//             <div className="AG_sideBarFooter">
//               <div className="sidebar-item">
//                 <img src={help} alt="Profile" />
//               </div>
//               <div className="sidebar-item">
//                 <NotificationsIcon className="notificationcolor"></NotificationsIcon>
//               </div>
//               <div className="sidebar-item-profile" onClick={this.logoutBtn}>
//                 <p>{this.state.currentUser}</p>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <div className="sidebar">
//             <ul>
//               {this.state.currentmenu.map((item) => {
//                 return (
//                   <li
//                     key={item.title}
//                     className={`issure-icons${
//                       item.title === "ESMA Reporting" &&
//                       sessionStorage.getItem("isESMA_Flag") === "No"
//                         ? " disabledESMA"
//                         : ""
//                     }`}
//                   >
//                     {item.title === "ESMA Reporting" &&
//                     sessionStorage.getItem("isESMA_Flag") === "No" ? (
//                       <div>
//                         {/* Render a disabledESMA version of the image */}
//                         <img
//                           alt="issuer icon"
//                           src={item.icon}
//                           className="disabledESMA"
//                         />
//                         {/* <span className="disabled-overlay"></span> */}
//                       </div>
//                     ) : (
//                       <div>
//                         <Link
//                           to={item.linkto}
//                           title={item.title}
//                           className={
//                             this.props.activeComponent === item.title &&
//                             !this.state.visible
//                               ? "issure-icon-active"
//                               : "issure-icon"
//                           }
//                         >
//                           {/* Render the link normally */}
//                           <img alt="issuer icon" src={item.icon} />
//                         </Link>
//                         {/* <div
//                           className={
//                             this.props.activeComponent === item.title
//                               ? "displayTitle-active"
//                               : "displayTitle-none"
//                           }
//                         >
//                           {item.title}
//                         </div> */}
//                       </div>
//                     )}
//                     {item.subitems != null && (
//                       <React.Fragment>
//                         <ul>
//                           <div className="arrow-left"></div>
//                           <div className="arrow_box">
//                             {item.subitems.map((item1) => (
//                               <li key={item1.title}>
//                                 <LinkItem
//                                   to={item1.linkto}
//                                   title={item1.title}
//                                 ></LinkItem>
//                               </li>
//                             ))}
//                           </div>
//                         </ul>
//                       </React.Fragment>
//                     )}
//                   </li>
//                 );
//               })}
//             </ul>
//             {/* <Tooltip title="LogOut">
//             <ul className="bottomMenu">
//               {/* <li className="userInfo"><LinkItem to={'#nogo'} title={this.state.currentUser} > </LinkItem></li> */}
//             {/* <li className="showMenu">  
//                 <Button className="logout" variant="contained" color="primary" type="submit" onClick={this.logoutBtn} >
//                   <ExitToAppIcon></ExitToAppIcon> 
//                 </Button>
//               </li>
              
//             </ul>
//             </Tooltip> */}

//             {this.state.currentUser.includes("AngeloGordon") ? (
//               <div
//                 className={`AI_Icon ${
//                   this.state.visible ? "issure-icon-active" : "issure-icon"
//                 }`}
//                 ref={this.wrapperRef}
//               >
//                 <img
//                   alt="issuer icon"
//                   src={AIChat}
//                   onClick={() =>
//                     this.setState({
//                       visible: !this.state.visible,
//                     })
//                   }
//                 />
//                 <h1
//                   className="Beta"
//                   onClick={() =>
//                     this.setState({
//                       visible: !this.state.visible,
//                     })
//                   }
//                 >
//                   BETA
//                 </h1>

//                 <SupportWindow visible={this.state.visible} />
//               </div>
//             ) : null}
//           </div>
//         )}
//       </React.Fragment>
//     );
//   }
// }


/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  IssuerIcons,
  ServicerMainDashboard,
  Investor_DashBoard,
  PendingUsersDashBoard,
  Issuer_DashBoard,
} from "./menu";
import LinkItem from "../linkItem";
import AIChat from "../../images/AIChat.png";
import SupportWindow from "../SupportWindow/SupportWindow";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AG_Inv_Icon from "../../images/AG_Inv_Icon.svg";
import help from "../../images/help.svg";
import "./sidebar.css";

export default class sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: sessionStorage.getItem("user_name"),
      OrgName: sessionStorage.getItem("OrgName"),
      userrole: sessionStorage.getItem("userrole"),
      ChannelName: sessionStorage.getItem("ChannelName"),
      loading: false,
      getLoansLoader: false,
      open: false,
      message: "",
      currentmenu: [],
      menu_loader: false,
      title: "",
      visible: false,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  logoutBtn() {
    sessionStorage.clear();
    window.location.replace("/");
  }

  async componentDidMount() {
    if (this.state.currentUser !== null) {
      const firstname = this.state.currentUser;
      const namefirstletter = firstname.charAt(0).toUpperCase();
      this.setState({ currentUser: namefirstletter });
    }

    if (this.state.currentUser === "Trustee") {
      this.setState({ currentmenu: IssuerIcons, menu_loader: true });
    } else if (this.state.currentUser === "AdminAG") {
      this.setState({ currentmenu: IssuerIcons, menu_loader: true });
    } else if (
      this.state.currentUser.includes("Investor") ||
      this.state.currentUser.includes("AngeloGordon")
    ) {
      this.setState({ currentmenu: Investor_DashBoard, menu_loader: true });
    } else if (this.state.currentUser === "Issuer") {
      this.setState({ currentmenu: Issuer_DashBoard, menu_loader: true });
    } else if (this.state.currentUser === "Issuer2") {
      this.setState({ currentmenu: Issuer_DashBoard, menu_loader: true });
    } else if (this.state.currentUser === "admin") {
      this.setState({ currentmenu: PendingUsersDashBoard, menu_loader: true });
    } else {
      this.setState({ currentmenu: ServicerMainDashboard, menu_loader: true });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ visible: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.menu_loader === false ? (
          ""
        ) : sessionStorage.getItem("user_name").includes("admin") || sessionStorage.getItem("user_name").includes("AngeloGordon") ? (
          <div className="sidebar Ag_Sidebar">
            <div>
              <div className="Ag_SidebarIcon">
                <img src={AG_Inv_Icon} alt="Profile" />
              </div>
              <ul>
                {this.state.currentmenu
                  .filter((item) => item.title !== "ESMA Reporting") // Exclude ESMA Reporting for Admin
                  .map((item) => {
                    return (
                      <li
                        key={item.title}
                        className={`issure-icons${
                          item.title === "ESMA Reporting" &&
                          sessionStorage.getItem("isESMA_Flag") === "No"
                            ? " disabledESMA"
                            : ""
                        }`}
                      >
                        <div>
                          <Link
                            to={item.linkto}
                            title={item.title}
                            className={
                              this.props.activeComponent === item.title &&
                              !this.state.visible
                                ? "issure-icon-active"
                                : "issure-icon"
                            }
                          >
                            <img alt="issuer icon" src={item.icon} />
                          </Link>
                        </div>
                        {item.subitems != null && (
                          <React.Fragment>
                            <ul>
                              <div className="arrow-left"></div>
                              <div className="arrow_box">
                                {item.subitems.map((item1) => (
                                  <li key={item1.title}>
                                    <LinkItem
                                      to={item1.linkto}
                                      title={item1.title}
                                    ></LinkItem>
                                  </li>
                                ))}
                              </div>
                            </ul>
                          </React.Fragment>
                        )}
                      </li>
                    );
                  })}
              </ul>
              {/* Beta section removed for Admin */}
            </div>
            <div className="AG_sideBarFooter">
              <div className="sidebar-item">
                <img src={help} alt="Profile" />
              </div>
              <div className="sidebar-item">
                <NotificationsIcon className="notificationcolor"></NotificationsIcon>
              </div>
              <div className="sidebar-item-profile" onClick={this.logoutBtn}>
                <p>{this.state.currentUser}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="sidebar">
            <ul>
              {this.state.currentmenu.map((item) => {
                return (
                  <li
                    key={item.title}
                    className={`issure-icons${
                      item.title === "ESMA Reporting" &&
                      sessionStorage.getItem("isESMA_Flag") === "No"
                        ? " disabledESMA"
                        : ""
                    }`}
                  >
                    <div>
                      <Link
                        to={item.linkto}
                        title={item.title}
                        className={
                          this.props.activeComponent === item.title &&
                          !this.state.visible
                            ? "issure-icon-active"
                            : "issure-icon"
                        }
                      >
                        <img alt="issuer icon" src={item.icon} />
                      </Link>
                    </div>
                    {item.subitems != null && (
                      <React.Fragment>
                        <ul>
                          <div className="arrow-left"></div>
                          <div className="arrow_box">
                            {item.subitems.map((item1) => (
                              <li key={item1.title}>
                                <LinkItem
                                  to={item1.linkto}
                                  title={item1.title}
                                ></LinkItem>
                              </li>
                            ))}
                          </div>
                        </ul>
                      </React.Fragment>
                    )}
                  </li>
                );
              })}
            </ul>

            {this.state.currentUser.includes("AngeloGordon") ? (
              <div
                className={`AI_Icon ${
                  this.state.visible ? "issure-icon-active" : "issure-icon"
                }`}
                ref={this.wrapperRef}
              >
                <img
                  alt="issuer icon"
                  src={AIChat}
                  onClick={() =>
                    this.setState({
                      visible: !this.state.visible,
                    })
                  }
                />
                <h1
                  className="Beta"
                  onClick={() =>
                    this.setState({
                      visible: !this.state.visible,
                    })
                  }
                >
                  BETA
                </h1>
                <SupportWindow visible={this.state.visible} />
              </div>
            ) : null}
          </div>
        )}
      </React.Fragment>
    );
  }
}
