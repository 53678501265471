/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
import { withSnackbar } from "notistack";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CircularProgress from "@material-ui/core/CircularProgress";
import { customStylesauto } from "../../../../../components/customscripts/customscript";
import CloseIcon from "@material-ui/icons/Close";
import { customStylesautosmallmodal1 } from "../../../../../components/customscripts/customscript";
import FormLoader from "../../../../../components/loader/formLoader";
import jsPDF from "jspdf";
import * as ExcelJS from "exceljs";
import { debounce } from "lodash";

// import "jspdf-autotable";
// import autoTable from "jspdf-autotable";
// import html2pdf from "html2pdf.js";

import {
  ViewConsolidatedReport,
  getPDFLogoData,
  CalculatePayments,
  CalculatePrePayments,
  CalculateCollateralPayments,
  CalculateConsolidatedPayments,
  PublishReport,
  getAllVersionsByPaymentDate,
  getAllPaymentDatesByDealName,
} from "../../../../../servies/services";
import NumberComp5 from "../../../../../components/NumberComp5";
import NumberComp6 from "../../../../../components/NumberComp6";
import * as moment from "moment";
import ReactModal from "react-modal";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import WSFS_Logo from "../../../../../images/wsfs-logo.jpg";
import BeanEater from "../../../../../images/BeanEater.gif";
import { customStylesautosmallmodalpopupBorrow } from "../../../../../components/customscripts/customscript";

class ConsolidatedRecurring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      downArrow: false,
      token: sessionStorage.getItem("token"),
      loading: false,
      tableData: [],
      formLoader1: false,
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      Version: sessionStorage.getItem("version"),
      ChangeTableColor: sessionStorage.getItem("colorTable"),
      VersionsChange: [],
      "Image Upload": "",
      "selected Color": "",
      OrgName: sessionStorage.getItem("OrgName"),
      TableName: "PaymentRules",
      formLoader: false,
      peers: JSON.parse(sessionStorage.getItem("peers")),
      peer: sessionStorage.getItem("peer"),
      isDisable: JSON.parse(sessionStorage.getItem("isdisable")),
      isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
      isSecuritisation: sessionStorage.getItem("isSecuritisation"),
      ChannelName: sessionStorage.getItem("ChannelName"),
      formData: {
        Name: "",
        LastModified: "",
      },
      formData1: {
        Name: "",
        LastModified: "",
      },
      deleteRowVal: {
        Name: "",
      },
      getLoansLoader: false,
      openPopup: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      neededHeaders: [],
      searchText: "",
      isBorrow: false,
      BorrowingBase: "False",
      rowsSelected: null,
      publishVersion: "",
      publishData: "",
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: true,
      activeInsights12: false,
      activeInsightsBB: false,
      isPublished: false,
      data: {},
      newModifiedData: {},
    };
    this.handleButtonClick = debounce(this.handleButtonClick.bind(this), 100);
  }
  showPrev = () => {
    window.location.assign("/admin/loantape_recurring");
  };
  showNext = () => {
    window.location.assign("/admin/files_recurring");
  };
  General = (value) => {
    sessionStorage.setItem("dealname", value);
    this.props.history.push({
      pathname: "/admin/general",
      state: { checkRecurring: true },
    });
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: true,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringBorrowingBase: false,
        checkRecurringPaymentRules: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };
  popoverBottom = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={() => this.General(this.state.DealName)}>Deal</button>
        <hr className="servicer-popover-hr" />
        <button
          onClick={() =>
            this.Loans(this.state.DealName, this.state?.NextPaymentDate)
          }
        >
          Loan Tape
        </button>
        {/* <hr className="servicer-popover-hr" />
        <button>Change Period</button> */}
      </Popover>
    );
  };

  generatePDF = () => {
    const pdf = new jsPDF({
      orientation: "landscape",
    });
    const catName = [];
    const margin = 20;
    const headerHeight = 10;
    const footerHeight = 10;
    const tables = document.querySelectorAll(".wrapper-pdf-container");
    const bgcolorheader = "#ededed";
    const addedCategories = [];
    const DealName = this.state.DealName;
    const DistributionDate = this.state.newModifiedData["Distribution Date"];
    const AssetClass = this.state.newModifiedData["Asset Class"];
    let totalPageCount = 0;
    const tableOfContents = [];
    const categoryFontStyle = "bold";
    const categoryFontColor = this.state["selected Color"];
    const PdfLogo = this.state["Image Upload"];
    const tocMargin = 5; // Set a margin for the TOC
    const tocPageLimit = 50;
    let temp = 0;
    let index = 0;
    let tocPageNumber = [];

    pdf.addPage();
    pdf.setPage(1);
    pdf.setDrawColor(categoryFontColor); // Set the line color to green
    const firstPageWidth = pdf.internal.pageSize.width;
    const firstPageHeight = pdf.internal.pageSize.height;
    const logoWidth = 85; // Adjust the logo width as needed
    const logoHeight = 30; // Adjust the logo height as needed
    const logoX = firstPageWidth - logoWidth - 20;
    const logoY = (firstPageHeight - logoHeight) / 2;
    pdf.addImage(PdfLogo, "JPEG", logoX, logoY, logoWidth, logoHeight);
    pdf.setLineWidth(3); // Set the line width
    pdf.line(0, 0, firstPageWidth, 0); // Top line
    pdf.line(0, firstPageHeight, firstPageWidth, firstPageHeight); // Bottom line

    pdf.line(0, 0, 0, firstPageHeight); // Left line
    pdf.line(firstPageWidth, 0, firstPageWidth, firstPageHeight);

    pdf.setFontSize(20); // Set the font size for the dynamic deal name
    pdf.setFont("bold");
    pdf.setTextColor(categoryFontColor);
    const dealNameWidth = pdf.getTextWidth(DealName);
    const distributionDateWidth = pdf.getTextWidth("Distribution Date");
    const reportTypeWidth = pdf.getTextWidth("Report Type");

    const maxWidth = Math.max(
      dealNameWidth,
      distributionDateWidth + reportTypeWidth + 30
    );

    pdf.text(DealName, firstPageWidth / 8, firstPageHeight / 6);

    pdf.setFontSize(12);
    pdf.setTextColor("black");
    pdf.setFont(categoryFontStyle);
    pdf.text(
      "Distribution Date ",
      (firstPageWidth - pdf.getTextWidth("Distribution Date")) / 7,
      firstPageHeight / 5 + 5
    );
    pdf.setFont("normal");
    pdf.text(
      DistributionDate,
      (firstPageWidth - pdf.getTextWidth("Distribution Date")) / 7,
      firstPageHeight / 5 + 12
    );
    pdf.setFont(categoryFontStyle);
    pdf.text(
      "Report Type",
      (firstPageWidth - pdf.getTextWidth("Report Type")) / 4 + 30,
      firstPageHeight / 5 + 5
    );
    pdf.setFont("normal");
    const detailsfreq = document.querySelectorAll("#frequency");
    detailsfreq.forEach((detailElement) => {
      const detailText = detailElement.innerText;
      var cancattext = "Report";
      var combained = detailText + " " + cancattext;
      pdf.setFontSize(12);
      pdf.setTextColor("black");
      pdf.text(
        combained,
        (firstPageWidth - pdf.getTextWidth("Report Type")) / 4 + 30,
        firstPageHeight / 5 + 12
      );
    });
    const boxWidth = 50;
    const boxHeight = 20;
    const boxX = (firstPageWidth - boxWidth) / 8 + 5;
    const boxY = firstPageHeight / 4 - boxHeight / 2 + 25;

    // Draw a rectangle
    pdf.setFillColor(categoryFontColor); // Set box fill color
    pdf.rect(boxX, boxY, boxWidth, boxHeight, "F");

    // Set text properties
    pdf.setFontSize(14);
    pdf.setTextColor("white");
    pdf.setFont(categoryFontStyle);
    // Get the dimensions of the text
    const text = "Investor Report";
    const textWidth = pdf.getTextWidth(text);
    const textHeight = pdf.internal.getLineHeight();

    // Calculate text position to center it in the box

    const textX = boxX + (boxWidth - textWidth) / 2;
    const textY = boxY + (boxHeight - textHeight) / 2 + 10;

    // Add text to the PDF

    pdf.text(text, textX, textY);

    let newY_CN = 128; // Initialize the Y-coordinate for General Details details

    const detailsElementscn = document.querySelectorAll("#CN");
    detailsElementscn.forEach((detailElement) => {
      const detailText = detailElement.innerText;
      pdf.setFontSize(12);
      pdf.setTextColor("black");
      pdf.text(detailText, margin + 15, newY_CN - 15);
      newY_CN += 9;
    });

    let newY_CI = newY_CN; // Initialize the Y-coordinate for General Details details

    const detailsElementsCI = document.querySelectorAll("#CI");

    // const detailsElementsCII = document.querySelectorAll("#CII");

    detailsElementsCI.forEach((detailElement) => {
      const detailText = detailElement.innerText;
      pdf.setFontSize(12);
      pdf.setTextColor("black");
      pdf.text(detailText, margin + 15, newY_CI - 15);
      newY_CI += 9;
    });

    const disclaimer = document.querySelectorAll("#DIS");
    disclaimer.forEach((detailElement) => {
      const detailText = detailElement.innerText;
      var cancattext = "Report";
      var combained = detailText + " " + cancattext;
      pdf.setFontSize(8);
      pdf.setTextColor("black");
      pdf.text(detailText, margin + 15, newY_CI + 7);
      newY_CI += 10;
    });

    // Function to create a header with a logo

    function createHeader() {
      if (pdf.internal.getNumberOfPages() > 1) {
        // Set the fill color to black
        // Draw a filled rectangle
        pdf.setFontSize(12); // Set the font size for the header text
        // const textColorValues = selected Color .substring(1).match(/.{1,2}/g).map(hex => parseInt(hex, 16));
        pdf.setTextColor(categoryFontColor);
        // Set text color (black) for the header text
        pdf.setFont(categoryFontStyle);
        // Calculate the width of the Deal Name text
        var dealNameWidth = pdf.getTextWidth(DealName);
        pdf.setFontSize(12);
        pdf.setFont(categoryFontStyle);
        pdf.text(DealName, margin - 15, margin - 10);
        pdf.setFillColor(bgcolorheader);
        pdf.rect(
          margin - 5 + dealNameWidth - 7,
          margin - 15,
          margin + 225,
          headerHeight - 1,
          "F"
        );
        var verticalLineX = margin - 5 + dealNameWidth - 30;
        pdf.setFontSize(12);
        pdf.setTextColor("black");
        var distributionDateWidth = pdf.getTextWidth("Distribution Date:");
        var distributionDateTextX = verticalLineX + 50;
        pdf.setFont(categoryFontStyle);
        pdf.text("Distribution Date : ", distributionDateTextX, margin - 10);
        pdf.setFont("normal");
        pdf.text(DistributionDate, distributionDateTextX + 33, margin - 10);
        var assetTypeTextX = distributionDateTextX + distributionDateWidth + 60;
        pdf.setDrawColor(categoryFontColor);
        pdf.text(AssetClass, assetTypeTextX, margin - 10);
        var horizontalline = margin + headerHeight + 1;
        pdf.setDrawColor(categoryFontColor);
        pdf.line(horizontalline - 30, margin, horizontalline + 300, margin);
      }
    }

    function createFooter(currentPage, totalPages) {
      if (currentPage > 1) {
        pdf.setPage(currentPage);
        pdf.setDrawColor(255, 255, 255); // Make the line invisible
        pdf.setFontSize(8);
        pdf.setTextColor(categoryFontColor);
        var horizontalline = margin + headerHeight + 1;
        pdf.setLineWidth(1);
        pdf.setDrawColor(categoryFontColor);
        pdf.line(
          horizontalline - 13,
          margin + 189,
          horizontalline + 300,
          margin + 189
        );
        pdf.text(
          `Page ${currentPage} of ${totalPages}`,
          margin + 257,
          pdf.internal.pageSize.height - footerHeight - 1
        );
        pdf.addImage(PdfLogo, "JPEG", margin - 20, margin + 185, 20, 6);

        if (currentPage === 2) {
          const lineYTop = pdf.internal.pageSize.height - footerHeight - 10;

          const availableSpace = pdf.internal.pageSize.width - 2;
          let remainingSpace = availableSpace; // Initialize remaining space
          const lineSpacing = -3.5; // Adjust the line spacing as needed
        }
      }
    }
    const tocEntries = []; // Array to store TOC entries with page numbers
    const tablePageNumbers = {};

    // Replace this function with your actual implementation to get the precise page number for a table
    function getPrecisePageNumberForTable(tableName, pageCount) {
      const tablePageMapping = { pageCount };
      return tablePageMapping[(tableName, pageCount)] || 1; // Default to 1 if the table is not found
    }

    function drawPageWithBox(contentElement, isContinuation, startY) {
      const boxX = margin;
      const boxY = startY || headerHeight + margin; // Use the provided startY or calculate from header height
      const boxWidth = pdf.internal.pageSize.width * margin;
      const boxHeight =
        pdf.internal.pageSize.height - headerHeight - footerHeight - 2 * margin; // Adjust for header and footer
      const pageText = contentElement.innerText;

      if (pdf.internal.getNumberOfPages() === 1) {
        // If it's the first page, move the text to the right
        pdf.setFontSize(12); // Set the font size for the first line
        pdf.setTextColor(categoryFontColor); // Set text color (red) for the first line

        // Calculate the horizontal and vertical positions to move the text to the right
        const textWidth = pdf.getStringUnitWidth(pageText) * 10; // Width of the text (adjust 20 as needed)
        const shiftAmount = (boxWidth - textWidth) / 2; // Calculate the shift to the right
        const textX = boxX + shiftAmount;
        const textY = boxY + boxHeight / 2;

        // Add the text to the page of the PDF inside the rectangle
        pdf.text(pageText, textX, textY);

        // Add a horizontal line under the table name
        pdf.setLineWidth(0.5); // Set the line width
        pdf.line(boxX, textY, boxX + boxWidth, textY);
      } else {
        // For other pages, use default styles
        pdf.setFontSize(12); // Set the font size for other pages
        pdf.setTextColor(categoryFontColor); // Set text color (black) for other pages

        // Add the text to the page of the PDF inside the rectangle
        const lines = pdf.splitTextToSize(pageText, boxWidth - 2); // Adjust the width as needed
        lines.forEach((line, index) => {
          pdf.text(line, boxX, boxY + index);
        });

        // Add a horizontal line under the table name
        pdf.setLineWidth(0.5); // Set the line width
        pdf.line(
          boxX,
          boxY + lines.length + 1,
          boxX + 25,
          boxY + lines.length + 1
        );
      }
    }

    function getPageNumberForCategoryName(categoryName) {
      console.log("tocEntries", categoryName, tocEntries);
      const entry = tocEntries.find(
        (tocEntry) =>
          tocEntry.categoryName.trim().toUpperCase() ===
          categoryName.trim().toUpperCase()
      );
      console.log("entry", entry);
      return entry ? entry.pageStart : null;
    }

    function createTOCEntry(categoryName, pageStart) {
      // Remove double spaces from categoryName
      const cleanedCategoryName = categoryName.replace(/\s+/g, " ");

      const existingEntryIndex = tocEntries.find(
        (entry) => entry.categoryName === cleanedCategoryName
      );

      if (!existingEntryIndex) {
        // Update the existing entry with the latest pageStart value
        // tocEntries[existingEntryIndex].pageStart = pageStart;
        tocEntries.push({ categoryName: cleanedCategoryName, pageStart });
      } else {
        // Add a new entry for the category
        // tocEntries.push({ categoryName: cleanedCategoryName, pageStart });
        return;
      }
      console.log("tocentryyy", cleanedCategoryName, pageStart);
    }

    pdf.setPage(2);

    let toc_y = margin;
    const detailsToc = document.querySelectorAll("#TOC");
    console.log("detail toc", detailsToc);
    detailsToc.forEach((detailsElement, i) => {
      let modifiedText = detailsElement.hash
        .replace(/%20/g, " ")
        .replace(/^#/, "");
      if (modifiedText === modifiedText.toUpperCase()) {
        catName.push(modifiedText);
      } else {
        catName.push(
          modifiedText
            .replace(/([A-Z])/g, " $1")
            .split("  ")
            .join(" ")
        );
      }
      const detailsText = detailsElement.innerText;
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      pdf.text(detailsText, margin, toc_y + 20);
      toc_y += 10;
    });

    let newep = margin + 10;
    const detailsElementep = document.querySelectorAll("#ep");
    detailsElementep.forEach((detailsElement) => {
      const detailText = detailsElement.innerText;
      pdf.setFont(categoryFontStyle);
      pdf.setFontSize(14);
      pdf.setTextColor("black");
      pdf.text(detailText, margin + 150, newep);
    });

    let newY_EP = margin + 20; // Initialize the Y-coordinate for External Parties details
    const detailsElements = document.querySelectorAll("#EP");
    const detailsElementEPP = document.querySelectorAll("#EPP");

    detailsElements.forEach((detailElement) => {
      const detailText = detailElement.innerText;
      pdf.setFontSize(12);
      pdf.setTextColor("black");
      pdf.text(detailText, margin + 150, newY_EP);
      newY_EP += 15;
    });

    pdf.setDrawColor(categoryFontColor);
    pdf.line(margin + 130, margin + 175, margin + 130, margin + 7);

    let newgd = Math.max(newY_EP, margin + 10);
    const detailsElementgd = document.querySelectorAll("#gd");
    detailsElementgd.forEach((detailsElement) => {
      const detailText = detailsElement.innerText;
      pdf.setFont(categoryFontStyle);
      pdf.setFontSize(14);
      pdf.setTextColor("black");
      pdf.text(detailText, margin + 150, newgd + 10);
    });

    let newY_GD = Math.max(newY_EP, margin + 20); // Initialize the Y-coordinate for General Details details
    const detailsElementsGD = document.querySelectorAll("#GD");
    const detailsElementsGDD = document.querySelectorAll("#GDD");

    detailsElementsGD.forEach((detailElement) => {
      const detailText = detailElement.innerText;
      pdf.setFontSize(12);
      pdf.setTextColor("black");
      pdf.text(detailText, margin + 150, newY_GD + 25);
      newY_GD += 15;
    });
    detailsElementsGDD.forEach((detailElement) => {
      const detailText = detailElement.innerText;
      pdf.setFontSize(12);
      pdf.setTextColor("black");
      pdf.text(detailText, margin + 150, newY_GD + 25);
      newY_GD += 15;
    });

    let serialNumber = 1;
    let pageCounter = 1;
    let currentPage = 2;
    let columnStyles = {};
    // function createTOCEntries() {
    //   tables.forEach((table, index) => {
    //     const sectionTitleElement = table.querySelector(".sectiontitle");
    //     var categoryName = sectionTitleElement
    //       ? sectionTitleElement.textContent
    //           .toLowerCase()
    //           .split(" ")
    //           .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    //           .join(" ")
    //           .trim()
    //       : "";
    //     if (!categoryName.trim("")) {
    //       return; // Skip empty categories
    //     }
    //     if (addedCategories.includes(categoryName)) {
    //       return; // Skip duplicate categories
    //     }
    //     // const pageStart = currentPage;
    //     const pageStart = pdf.internal.getNumberOfPages() + 1;

    //     addedCategories.push(
    //       categoryName,
    //       categoryFontStyle,
    //       categoryFontColor
    //     );

    //     pdf.addPage();

    //     createHeader();
    //     drawPageWithBox(sectionTitleElement, true);

    //     const tableContentElements = table.querySelectorAll(".table");

    //     tableContentElements.forEach((tableContentElement, i) => {
    //       const tableRows = tableContentElement.querySelectorAll("tr");
    //       if (tableContentElements.length > 0) {
    //         if (tableRows.length > 0) {
    //           tableRows.forEach((row, rowIndex) => {
    //             let rowCells;
    //             if (rowIndex === 0) {
    //               return;
    //             } else {
    //               rowCells = row.innerText
    //                 .split("\t")
    //                 .filter((value) => value.trim() !== "")
    //                 .map((value) => value.replace(/\n/g, ""));
    //               console.log("row", rowCells);
    //             }
    //             rowCells.forEach((cell, columnIndex) => {
    //               console.log("cellContent", cell, typeof cell);
    //               if (columnIndex === 0) {
    //                 columnStyles[columnIndex] = { halign: "left" };
    //               } else if (cell.split("").some((char) => char == "%")) {
    //                 columnStyles[columnIndex] = { halign: "center" };
    //               } else if (!isNaN(parseFloat(cell.replace(/,/g, "")))) {
    //                 columnStyles[columnIndex] = { halign: "right" };
    //               } else {
    //                 columnStyles[columnIndex] = { halign: "center" };
    //               }
    //             });
    //           });
    //         }
    //         if (tableContentElements.length > 0) {
    //           currentPage = pdf.internal.getNumberOfPages() + 1;
    //         }
    //         console.log("columnStyles", columnStyles);
    //       }
    //       pdf.autoTable({
    //         theme: "grid",
    //         html: tableContentElement,
    //         margin: { top: 35, right: margin, bottom: margin, left: margin },
    //         bodyStyles: { fontSize: 10, cellWidth: "auto", textColor: "black" },
    //         columnStyles: columnStyles,
    //         themeStyles: {
    //           fillStyle: categoryFontColor,
    //           textColor: categoryFontColor,
    //         },
    //         headStyles: {
    //           fillColor: categoryFontColor,
    //           halign: "center",
    //           fontSize: 12,
    //         },
    //       });
    //       createHeader();
    //       createTOCEntry(categoryName, pdf.internal.getNumberOfPages());
    //     });

    //     pageCounter = pdf.internal.getNumberOfPages();
    //   });
    // }
    function createTOCEntries() {
      tables.forEach((table, index) => {
        const sectionTitleElement = table.querySelector(".sectiontitle");
        var categoryName = sectionTitleElement
          ? sectionTitleElement.textContent
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
              .trim()
          : "";
        if (!categoryName.trim("")) {
          return; // Skip empty categories
        }
        if (addedCategories.includes(categoryName)) {
          return; // Skip duplicate categories
        }
        // const pageStart = currentPage;
        createTOCEntry(categoryName, pdf.internal.getNumberOfPages() + 1);

        addedCategories.push(
          categoryName,
          categoryFontStyle,
          categoryFontColor
        );

        console.log("CategoryName", categoryName);

        // createTOCEntry(categoryName, pageStart);
        // pdf.setPage(1); // Set the page to the first page for TOC entries
        // pdf.setFont(categoryFontStyle);
        // pdf.setFontSize(12); // Set the font size for TOC entries
        // pdf.setTextColor(categoryFontColor); // Set text color (black) for TOC entries
        // const sNoX = margin + 3;
        // const categoryNameX = 20;
        // const pageX = margin + 105;
        // console.log("categoryName", categoryName);

        pdf.addPage();

        createHeader();
        drawPageWithBox(sectionTitleElement, true);

        const tableContentElements = table.querySelectorAll(".table");

        tableContentElements.forEach((tableContentElement, i) => {
          const tableRows = tableContentElement.querySelectorAll("tr");
          if (tableContentElements.length > 0) {
            if (tableRows.length > 0) {
              tableRows.forEach((row, rowIndex) => {
                let rowCells;
                if (rowIndex === 0) {
                  return;
                } else {
                  rowCells = row.innerText
                    .split("\t")
                    .filter((value) => value.trim() !== "")
                    .map((value) => value.replace(/\n/g, ""));
                  console.log("row", rowCells);
                }
                rowCells.forEach((cell, columnIndex) => {
                  console.log("cellContent", cell, typeof cell);
                  if (columnIndex === 0) {
                    columnStyles[columnIndex] = { halign: "left" };
                  } else {
                    columnStyles[columnIndex] = { halign: "right" };
                  }
                });
              });
            }
            if (tableContentElements.length > 0) {
              currentPage = pdf.internal.getNumberOfPages() + 1;
            }
            console.log("columnStyles", columnStyles);
          }
          pdf.autoTable({
            theme: "striped",
            html: tableContentElement,
            margin: { top: 35, right: margin, bottom: margin, left: margin },
            bodyStyles: { fontSize: 10, cellWidth: "auto", textColor: "black" },
            columnStyles: columnStyles,
            themeStyles: {
              fillStyle: categoryFontColor,
              textColor: categoryFontColor,
            },
            headStyles: {
              fillColor: categoryFontColor,
              halign: "center",
              fontSize: 10,
            },
          });
          createHeader();
          // createTOCEntry(categoryName, pdf.internal.getNumberOfPages());
        });

        pageCounter = pdf.internal.getNumberOfPages();
      });
    }
    createTOCEntries(); // Call the function to generate TOC entries
    totalPageCount = pdf.internal.getNumberOfPages();
    tocEntries.forEach((cat) => {
      catName.forEach((catname) => {
        const pageNumber = getPageNumberForCategoryName(catname);
        if (!tocPageNumber.includes(pageNumber)) {
          tocPageNumber.push(pageNumber);
        }
      });
    });

    for (let i = 1; i <= totalPageCount; i++) {
      createHeader();
      createFooter(i, totalPageCount);
    }
    let yPos = margin + 20;
    pdf.setPage(2);
    for (let i = 0; i < tocPageNumber.length; i++) {
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      pdf.text(tocPageNumber[i].toString(), margin + 105, yPos);
      yPos += 10;
    }
    pdf.setPage(2); // Set the font family for the table of contents title
    pdf.setTextColor("black");
    pdf.setFontSize(14); // Set the font size for the table of contents title
    pdf.setPage(2);
    pdf.setFont(categoryFontStyle);
    pdf.text("Table of Contents", margin + 10, margin + 10);
    pdf.text("Page", margin + 100, margin + 10);
    // pdf.text("External Parties", margin + 150, margin + 10);
    // pdf.text("General Details", margin + 150, margin + 72);
    pdf.setPage(1);
    pdf.setFontSize(14);
    pdf.setTextColor(categoryFontColor);
    pdf.setFont(categoryFontStyle);
    pdf.text("Contact Information", margin + 15, margin + 80);
    pdf.save("Investor Report.pdf");
  };

  //   handleDownloadExcel = () => {
  //     const headings = document.querySelectorAll(".headingExcel");
  //     const tables = document.querySelectorAll(".allTableExcel");
  //     const ContentRight = document.querySelectorAll(".ExcelRight");
  //     const wb = XLSX.utils.book_new();
  //     const wsData = [];

  //     headings.forEach((heading, index) => {
  //       // Add heading to worksheet data with red text color
  // wsData.push([
  //   {
  //     v: heading.textContent.trim(),
  //     s: { fill: { patternType: "solid", fgColor: { rgb: "blue" } } },
  //     t: "s",
  //   },
  // ]);
  //       // Add a gap between heading and table data
  //       wsData.push([]);

  //       // Add corresponding table data
  //       const table = tables[index];
  //       const rows = table.querySelectorAll("tr");

  //       rows.forEach((row) => {
  //         const cells = row.querySelectorAll("td, th");
  //         const rowData = [];

  //         cells.forEach((cell) => {
  //           rowData.push(cell.textContent.trim());
  //         });

  //         if (rowData.length > 0) {
  //           wsData.push(rowData);
  //         }
  //       });

  //       // Add a gap after the table data
  //       wsData.push([]);
  //     });

  //     const ws = XLSX.utils.aoa_to_sheet(wsData);
  //     XLSX.utils.book_append_sheet(wb, ws, "Investor Report");
  //     XLSX.writeFile(wb, "Investor Report.xlsx");
  //   };

  handleDownloadExcel = () => {
    const headings = document.querySelectorAll(".headingExcel");
    const tables = document.querySelectorAll(".allTableExcel");
    const downloadTables = document.querySelectorAll(".DownloadCollection");
    const ContentRight = document.querySelector(".ExcelRight");
    const rightSideDealContent = document.querySelector(
      ".rightSideDealContent"
    );
    // console.log({ ContentRight });
    const wb = new ExcelJS.Workbook();

    // Add a worksheet to the workbook
    const ws = wb.addWorksheet("Investor Report");

    // Define style for headers
    const headerStyle = {
      font: { color: { argb: "003aff" }, bold: true },
    };

    // Define style for hardcoded text color
    const hardcodedTextStyle = {
      font: { color: { argb: "e56811" } },
    };

    // Loop through content and add to worksheet
    rightSideDealContent
      .querySelectorAll(".rightSideDealContent > div")
      .forEach((element) => {
        if (!element) {
          console.warn("Warning: Element not found.");
          return;
        }

        // console.log("?????", element);

        // If the element has className 'dealConso', it is a header
        if (element.querySelector(".dealConso")) {
          const h4Content = element
            .querySelector(".dealConso")
            .textContent.trim();
          ws.addRow([h4Content]); // Add h4 content to the worksheet
          ws.lastRow.eachCell((cell) => {
            cell.style = headerStyle;
          });
        }

        // If the element is a label and its parent div is not hidden, add its content to the worksheet
        if (element.querySelector("label")) {
          const label = element.querySelector("label");
          // Extract hardcoded text from label's child nodes
          // Extract hardcoded text directly from the label element
          const hardcodedText = label.childNodes[0].nodeValue.trim();

          // Extract dynamic content from label's text content (excluding hardcoded text)
          const dynamicContent = label.textContent
            .replace(hardcodedText, " ") // Remove hardcoded text from the label's text content
            .trim(); // Trim whitespace

          // Add both hardcoded text and dynamic content to the worksheet
          ws.addRow([hardcodedText, dynamicContent]);

          // Apply style to hardcoded text cell
          const rowNumber = ws.rowCount;
          ws.getCell(`A${rowNumber}`).style = hardcodedTextStyle;
        }

        // Add an empty row as a gap
      });

    // Loop through content and add to worksheet
    ContentRight.querySelectorAll(".dealInfo, label").forEach((element) => {
      if (!element) {
        console.warn("Warning: Element not found.");
        return;
      }

      // If the element has className 'dealInfo', it is a header
      if (element.classList.contains("dealInfo")) {
        ws.addRow([]);
        ws.addRow([element.textContent.trim()]);
        ws.lastRow.eachCell((cell) => {
          cell.style = headerStyle;
        });
      }

      // If the element is a label and its parent div is not hidden, add its content to the worksheet
      if (
        element.tagName.toLowerCase() === "label" &&
        !element.parentElement.hasAttribute("hidden")
      ) {
        const labelText = element.childNodes[0]
          ? element.childNodes[0].textContent.trim()
          : ""; // Extract only hardcoded text
        const h6Elements = element.querySelectorAll("h6");
        let h6Text = "";
        if (h6Elements.length > 0) {
          h6Text = Array.from(h6Elements).map((h6) => h6.textContent.trim());
        }

        // Add label content and h6 content side by side
        if (h6Text.length > 0) {
          const row = ws.addRow([labelText]); // Add label text to the first cell
          h6Text.forEach((text, index) => {
            row.getCell(index + 2).value = text; // Add each h6 text to the next cell in the same row
            row.getCell(1).style = hardcodedTextStyle; // Apply style to the first cell
          });
        } else {
          const row = ws.addRow([labelText, ""]); // Add label text to the first cell and an empty cell for h6 text
          row.getCell(1).style = hardcodedTextStyle; // Apply style to the first cell
        }
      }

      // Add an empty row as a gap
      // ws.addRow([]);
    });

    // Define style for red text
    const blueTextStyle = {
      font: { color: { argb: "003aff" }, bold: true },
    };

    // Define style for yellow text
    const yellowTextStyle = {
      font: { color: { argb: "e56811" }, bold: true },
    };

    // Initialize heading index
    let currentHeadingIndex = 0;

    // Loop through headings and add to worksheet with red text color
    headings.forEach((heading, index) => {
      // Add heading with blue text color
      ws.addRow([heading.textContent.trim()]).getCell(1).style = blueTextStyle;

      // Add a gap between heading and table data
      ws.addRow([]);

      // Add corresponding table data
      const table = tables[index];
      const rows = table.querySelectorAll("tr");

      // console.log({ rows });

      rows.forEach((row, rowIndex) => {
        const cells = row.querySelectorAll("td, th");
        const rowData = [];

        cells.forEach((cell) => {
          rowData.push(cell.textContent.trim());
        });

        if (rowData.length > 0) {
          // Add the first row with yellow text color
          const currentRow = ws.addRow(rowData);
          if (rowIndex === 0) {
            if (currentHeadingIndex === index) {
              // Apply yellowTextStyle only if it's the first table under this heading
              currentRow.eachCell((cell) => {
                cell.font = yellowTextStyle.font;
              });
            }
            currentHeadingIndex++;
          }
        }
      });

      // Add a gap after the table data
      ws.addRow([]);
    });

    // Write workbook to a buffer
    wb.xlsx
      .writeBuffer()
      .then((buffer) => {
        // Create a Blob from the buffer
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a link element and trigger download
        const a = document.createElement("a");
        a.href = url;
        a.download = "Investor Report.xlsx";
        a.click();

        // Revoke the URL object
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error generating Excel file:", error);
      });
  };

  popoverBottomDownload = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={this.generatePDF}>PDF</button>
        <hr className="servicer-popover-hr" />
        <button onClick={this.handleDownloadExcel}>Excel</button>
        {/* <hr className="servicer-popover-hr" />
        <button>Change Period</button> */}
      </Popover>
    );
  };

  handleButtonClick() {
    this.setState((prevState) => ({
      downArrow: !prevState.downArrow,
    }));
  }

  handleOverlayHide = () => {
    this.setState({ downArrow: false });
  };

  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  onOpenModal = () => {
    this.setState({ open1: true });
  };
  onCloseModal = () => {
    this.setState({ open1: false });
  };
  //   onOpenModal1 = () => {
  //     this.setState({ open2: true });
  //   };
  onCloseModal1 = () => {
    this.setState({ open2: false });
  };

  goBackToDashboard = () => {
    sessionStorage.removeItem("colorTable");
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  handleClickGeneral = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/general_recurring");
  };

  handleClickTranches = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/tranches_recurring");
  };
  handleClickFees = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/fees_recurring");
  };
  handleClickExpenses = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/expenses_recurring");
  };
  handleClickAccounts = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/accounts_recurring");
  };
  handleClickTests = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: true,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/tests_recurring");
  };
  handleBorrowingBase = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: true,
    });
    window.location.assign("/admin/borrowingbase_recurring");
  };
  handleClickVariables = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: true,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/variable_recurring");
  };
  handleClickPaymentRules = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: true,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/payment_recurring");
  };
  handleClickCollateral = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: true,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/collateral_recurring");
  };

  handleClickLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: true,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/loantape_recurring");
  };

  handleClickConsolidated = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: true,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/consolidated_recurring");
  };

  handleClickFiles = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: true,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/files_recurring");
  };

  onCloseModalBorrow = () => {
    this.setState({ isBorrow: false });
  };
  onOpenModalBorrow = () => {
    this.setState({ isBorrow: true });
  };
  checkBorrowBaseYes = async () => {
    this.setState(
      { calculateBorrow: true, BorrowingBase: "True", isBorrow: false },
      async () => {
        await this.CalculatePrePayments();
      }
    );
  };
  checkBorrowBaseNo = async () => {
    this.setState(
      { calculateBorrow: false, BorrowingBase: "False", isBorrow: false },
      async () => {
        await this.CalculatePrePayments();
      }
    );
  };

  CalculatePrePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.NextPaymentDate = this.state?.NextPaymentDate;
    data.LastPaymentDate = this.state?.LastPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePrePayments(data);

    console.log("AccountsCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        // const message = APIResponse.data.message;
        // this.props.enqueueSnackbar(message, {
        //   variant: "info",
        //   autoHideDuration: 3000,
        // });
        this.CalculatePayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: false,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringBorrowingBase: false,
        checkRecurringPaymentRules: false,
        checkRecurringCollateral: true,
        checkRecurringLoanTape: false,
        checkRecurringConsolidated: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  DownloadConsolidatedPdf = async () => {
    const pdf = new jsPDF();

    // Define the getfirstPage element for the PDF content
    const getfirstPage = document.getElementById("generatePdf");

    const tables = document.querySelectorAll(".Download");
    const tableNames = document.querySelectorAll(".headingTable");
    const tablesForCPR = document.querySelectorAll(".DownloadCPR");
    const tableNamesForCPR = document.querySelectorAll(".headingTableCPR");
    const tablesForCollection = document.querySelectorAll(
      ".DownloadCollection"
    );
    const tableNamesForCollection = document.querySelectorAll(
      ".headingTableCollection"
    );

    // console.log("tablesForCPR",tables,tablesForCPR);

    const maxPageWidth = pdf.internal.pageSize.getWidth() - 20;

    // Function to add page number in the footer
    const addPageNumber = () => {
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.setFont("courier", "normal");
        pdf.setFontSize(12);
        pdf.text(
          `Page ${i} of ${pageCount}`,
          pdf.internal.pageSize.getWidth() / 2,
          pdf.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      }
    };

    // const addHeaderContent = () => {
    //   pdf.setPage(1); // Ensure that we are on the first page
    //   const element = document.getElementById("generatePdf");

    //   // Use html2pdf to convert the HTML content to PDF
    //   html2pdf(element)
    //     .from(element)
    //     .set({
    //       margin: [15, 15],
    //     });
    // };

    const processTable = (table, tableName) => {
      pdf.setTextColor(4, 140, 136);
      pdf.setFont("courier", "bold");
      pdf.setFontSize(15);
      pdf.text(tableName, 13, 15);
      const textWidth =
        (pdf.getStringUnitWidth(tableName) * pdf.internal.getFontSize()) /
        pdf.internal.scaleFactor;
      const startX = 13;
      const startY = 12;
      const lineHeight = 5;
      pdf.setDrawColor(4, 140, 136);
      pdf.setLineWidth(0.5);
      pdf.line(
        startX,
        startY + lineHeight,
        startX + textWidth,
        startY + lineHeight
      );

      // Calculate maximum column widths
      const columnWidths = [];
      table.querySelectorAll("thead th").forEach((headerCell, columnIndex) => {
        const headerCellWidth =
          (pdf.getStringUnitWidth(headerCell.textContent) *
            pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const dataCells = table.querySelectorAll(
          `tbody td:nth-child(${columnIndex + 1})`
        );
        const maxDataCellWidth = Array.from(dataCells).reduce(
          (maxWidth, dataCell) => {
            const dataCellWidth =
              (pdf.getStringUnitWidth(dataCell.textContent) *
                pdf.internal.getFontSize()) /
              pdf.internal.scaleFactor;
            return Math.max(maxWidth, dataCellWidth);
          },
          0
        );
        columnWidths[columnIndex] = Math.max(headerCellWidth, maxDataCellWidth);
      });

      // Set a fixed width for each column
      const fixedColumnWidth = Math.max(...columnWidths);

      // Create an array of equal width for each column
      const equalWidths = Array(columnWidths.length).fill(fixedColumnWidth);

      pdf.autoTable({
        html: table,
        startY: 20,
        headStyles: { fillColor: [4, 140, 136] },
        margin: { top: 20, bottom: 10 },
        theme: "grid",
        styles: {
          lineColor: [222, 226, 230], // Set lineColor to #f1f1f1
          lineWidth: 0.3,
        },
        columnStyles: { 0: { cellWidth: equalWidths } },
      });
    };

    const processTableCPR_Collection = (table, tableName, index) => {
      if (index === 0) {
        pdf.setTextColor(4, 140, 136);
        pdf.setFont("courier", "bold");
        pdf.setFontSize(15);
        pdf.text(tableName.trim(), 13, 15);
        pdf.setDrawColor(4, 140, 136);
        const textWidth =
          (pdf.getStringUnitWidth(tableName.trim()) *
            pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const startX = 13;
        const startY = 12;
        const lineHeight = 5;
        pdf.setLineWidth(0.5);
        pdf.line(
          startX,
          startY + lineHeight,
          startX + textWidth,
          startY + lineHeight
        );
      }

      pdf.autoTable({
        html: table,
        startY: 20,
        headStyles: { fillColor: [4, 140, 136] },
        margin: { top: 20, bottom: 10 },
        theme: "grid",
        styles: {
          lineColor: [222, 226, 230], // Set lineColor to #f1f1f1
          lineWidth: 0.3,
        },
        columnStyles: function (columnIndex) {
          return {
            cellWidth: this.getColumnStyles(table, maxPageWidth)[columnIndex]
              .cellWidth,
          };
        },
      });
    };

    // addHeaderContent();
    tables.forEach((table, index) => {
      // if (index === 0) {
      //   // Add "Hello" text on the first page
      // }

      let tableName = `Table ${index + 1}`;

      if (tableNames[index]) {
        let baseTableName = tableNames[index].textContent;
        console.log(">>>>>>>>>>>>>>>>>>", index);
        console.log("<<<<<<<<<<<<<<", baseTableName);

        tableName = baseTableName.trim();
        processTable(table, tableName);
      }

      if (index < tables.length - 1) {
        pdf.addPage();
      }

      if (index === 12 && tablesForCPR.length > 0 && tableNamesForCPR[0]) {
        for (let i = 0; i < tablesForCPR.length; i++) {
          if (tablesForCPR[i]) {
            processTableCPR_Collection(
              tablesForCPR[i],
              tableNamesForCPR[0].textContent,
              i
            );
            pdf.addPage();
          }
        }
      }

      if (
        index === 4 &&
        tablesForCollection.length > 0 &&
        tableNamesForCollection[0]
      ) {
        for (let i = 0; i < tablesForCollection.length; i++) {
          if (tablesForCollection[i]) {
            processTableCPR_Collection(
              tablesForCollection[i],
              tableNamesForCollection[0].textContent,
              i
            );
            pdf.addPage();
          }
        }
      }
    });
    // Add page numbers to each page
    addPageNumber();
    pdf.save("consolidated.pdf");
  };

  getColumnStyles(table, maxPageWidth) {
    const columnStyles = {};
    const numColumns = table.rows[0].cells.length;

    const minHeaderWidth = 40; // Set your minimum width for header cells

    for (let colIndex = 0; colIndex < numColumns; colIndex++) {
      let maxWidth = 0;
      let maxHeaderWidth = 0;

      for (let rowIndex = 0; rowIndex < table.rows.length; rowIndex++) {
        const cell = table.rows[rowIndex].cells[colIndex];
        const cellWidth = cell.getBoundingClientRect().width;

        if (rowIndex === 0) {
          // Header cell
          maxHeaderWidth = Math.max(maxHeaderWidth, cellWidth);
        } else {
          // Data cell
          maxWidth = Math.max(maxWidth, cellWidth);
        }
      }

      // Adjust header cell width based on header content and minimum width
      const adjustedHeaderWidth = Math.max(
        minHeaderWidth,
        Math.min(maxHeaderWidth, maxPageWidth / numColumns)
      );

      // Distribute header cell width among data columns
      if (table.rows[0].cells[colIndex].nodeName === "TH") {
        const dataColumnWidth = Math.min(
          maxWidth / (numColumns - 1),
          maxPageWidth / numColumns
        );

        for (let i = 0; i < numColumns; i++) {
          columnStyles[colIndex + i] = {
            cellWidth: i === 0 ? adjustedHeaderWidth : dataColumnWidth,
          };
        }
      } else {
        columnStyles[colIndex] = {
          cellWidth: Math.min(maxWidth, maxPageWidth / numColumns),
        };
      }
    }

    return columnStyles;
  }

  // // Function to dynamically calculate column widths based on content
  //  getColumnStyles(table, maxPageWidth) {
  //   const columnStyles = {};
  //   const numColumns = table.rows[0].cells.length;

  //   for (let colIndex = 0; colIndex < numColumns; colIndex++) {
  //       let maxWidth = 0;

  //       // Iterate through rows to find maximum width in the column
  //       for (let rowIndex = 0; rowIndex < table.rows.length; rowIndex++) {
  //           const cell = table.rows[rowIndex].cells[colIndex];
  //           const cellWidth = cell.getBoundingClientRect().width;
  //           maxWidth = Math.max(maxWidth, cellWidth);
  //       }

  //       // Set the maximum width as the column width, but not exceeding the maxPageWidth
  //       columnStyles[colIndex] = {
  //           cellWidth: Math.min(maxWidth, maxPageWidth / numColumns),
  //       };
  //   }

  //   return columnStyles;
  // }

  CalculatePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.BorrowingBase = this.state.BorrowingBase;
    data.peers = this.state.peers;
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePayments(data);

    console.log("PaymentCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculateCollateralPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateCollateralPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateCollateralPayments(data);

    console.log("GeneralCalculateCollateralPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculateConsolidatedPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateConsolidatedPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateConsolidatedPayments(data);

    console.log("PaymentCalculateConsolidatedPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  ViewConsolidatedReport = async () => {
    this.setState({
      getLoansLoader: true,
      data: {},
      loading: true,
      screenloader: true,
    });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.Version = this.state.isPublished
      ? this.state.publishVersion
      : this.state.Version;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await ViewConsolidatedReport(data);

    // console.log("ViewConsolidatedReport", APIResponse.data.data);
    if (APIResponse.status === 200) {
      function filterArrays(inputObj, excludeKeys = []) {
        const result = {};

        for (const key in inputObj) {
          if (!excludeKeys.includes(key) && Array.isArray(inputObj[key])) {
            // Check if the key is not in the excludeKeys array and if the value is an array
            result[key] = inputObj[key]; // Copy the array value to the result object
          }
        }

        return result;
      }
      const parsedData = filterArrays(APIResponse.data.data, [
        "Deal Name",
        "Distribution Date",
        "External Parties",
        "General Details",
        "Asset Class",
      ]);

      // console.log(">>>>>>>>>>>", parsedData);
      this.setState({
        getLoansLoader: false,
        newModifiedData: APIResponse.data.data,
        data: parsedData,
        loading: false,
        openModalPayment: false,
        screenloader: false,
        isPublished: false,
        publishData: APIResponse.data.publish,
      });
      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
      //window.location.assign("/admin/previewmapfields");
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        isPublished: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        isPublished: false,
      });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  PublishReport = async () => {
    this.setState({ getLoansLoader: true, formLoader1: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.Version = this.state.publishVersion;
    data.Status = "1";
    data.CurrentPaymentDate = this.state?.NextPaymentDate;
    data.userId = sessionStorage.getItem("user_id");
    data.peers = this.state.peers;

    console.log("datata", data);
    const APIResponse = await PublishReport(data);

    console.log("PublishReport", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader1: false,
          isPublished: true,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ isPublished: false }, async () => {
          await this.ViewConsolidatedReport();
        });
        this.setState({ isPublished: true }, async () => {
          await this.getAllVersionsByPaymentDate();
        });
        // this.getAllVersionsByPaymentDate();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader1: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  arrayBufferToDataURL(arrayBuffer) {
    const blob = new Blob([arrayBuffer], { type: "image/jpeg" }); // Change the MIME type accordingly
    return URL.createObjectURL(blob);
  }

  getPDFLogoData = async () => {
    this.setState({ screenloader: true });
    var data = {};
    data.ChannelName = this.state.ChannelName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getPDFLogoData(data);
    console.log({ APIResponse });
    if (APIResponse.status === 200) {
      const retrivedColor = JSON.parse(APIResponse?.data["ColorCode"]);

      const bufferData = APIResponse?.data.PdfLogo;
      const dataURL = `data:image/jpeg;base64,${Buffer.from(
        bufferData
      ).toString("base64")}`;

      // console.log(".................", dataURL);

      this.setState(
        {
          "Image Upload": dataURL,
          "selected Color": retrivedColor.hex,
        }
        // () =>
        //   console.log(
        //     ">>>>>>>>>>>>>>>>>>>>>>>>>>",
        //     this.state["Image Upload"],
        //     this.state["selected Color"]
        //   )
      );

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
    } else {
      this.setState({ screenloader: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);

    if (this.state.isLoansProcessed === "No") {
      window.location.assign("/admin/general_recurring");
    }

    const { Version, getDashboardVersions } = this.state;

    if (
      (Version === "WIP" || Version === "V1") &&
      getDashboardVersions.length === 1
    ) {
      this.setState(
        { isDisable: false, publishVersion: Version, Version: "WIP" },
        async () => {
          await this.getPDFLogoData();
          await this.ViewConsolidatedReport();
        }
      );
      const links = document.querySelectorAll(".linkToTable a");

      // console.log("check??????????????", links);

      links.forEach(function (link) {
        link.addEventListener("click", function (e) {
          e.preventDefault();

          const targetId = link.getAttribute("href").substring(1);
          const targetElement = document.getElementById(targetId);

          if (targetElement) {
            window.scrollTo({
              top: targetElement.offsetTop,
              behavior: "smooth",
            });
          }
        });
      });
    } else {
      function getMaxVersion(versions) {
        let highestNumber = -1;
        let prefix = "";

        for (const version of versions) {
          const numberMatch = version.match(/\d+/); // Extract the number using regex
          if (numberMatch) {
            const number = parseInt(numberMatch[0]);
            if (number > highestNumber) {
              highestNumber = number;
              prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
            }
          }
        }

        if (highestNumber >= 0) {
          return prefix + highestNumber;
        } else {
          // Handle the case where no valid version numbers were found
          return null;
        }
      }

      const latestVersion = getMaxVersion(getDashboardVersions);
      if (Version !== latestVersion) {
        this.setState({
          isDisable: this.state.isDisable,
          publishVersion: Version,
        });
        await this.getPDFLogoData();
        await this.ViewConsolidatedReport();
        const links = document.querySelectorAll(".linkToTable a");

        links.forEach(function (link) {
          link.addEventListener("click", function (e) {
            e.preventDefault();

            const targetId = link.getAttribute("href").substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
              window.scrollTo({
                top: targetElement.offsetTop,
                behavior: "smooth",
              });
            }
          });
        });
      } else {
        this.setState(
          { isDisable: false, publishVersion: Version, Version: "WIP" },
          async () => {
            await this.getPDFLogoData();
            await this.ViewConsolidatedReport();
          }
        );
        const links = document.querySelectorAll(".linkToTable a");

        links.forEach(function (link) {
          link.addEventListener("click", function (e) {
            e.preventDefault();

            const targetId = link.getAttribute("href").substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
              window.scrollTo({
                top: targetElement.offsetTop,
                behavior: "smooth",
              });
            }
          });
        });
      }
    }

    // if (this.state.Version === "V1" || this.state.Version === "V2") {
    //   this.setState({ isDisable: this.state.isDisable });
    //   this.ViewConsolidatedReport();
    //   const links = document.querySelectorAll(".linkToTable a");

    //   links.forEach(function (link) {
    //     link.addEventListener("click", function (e) {
    //       e.preventDefault();

    //       const targetId = link.getAttribute("href").substring(1);
    //       const targetElement = document.getElementById(targetId);

    //       if (targetElement) {
    //         window.scrollTo({
    //           top: targetElement.offsetTop,
    //           behavior: "smooth",
    //         });
    //       }
    //     });
    //   });
    // } else {
    //   this.setState({ isDisable: false });
    //   this.ViewConsolidatedReport();
    //   const links = document.querySelectorAll(".linkToTable a");

    //   links.forEach(function (link) {
    //     link.addEventListener("click", function (e) {
    //       e.preventDefault();

    //       const targetId = link.getAttribute("href").substring(1);
    //       const targetElement = document.getElementById(targetId);

    //       if (targetElement) {
    //         window.scrollTo({
    //           top: targetElement.offsetTop,
    //           behavior: "smooth",
    //         });
    //       }
    //     });
    //   });
    // }
  }

  generateIndividualTables() {
    const { data } = this.state;

    const generateHeaders = (category) => {
      // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@", category);
      if (
        category.startsWith("LoanDetails") &&
        category !== "Collection Account Detail" &&
        category !== "Prepayment and Default Rates"
      ) {
        return ["Loan ID", "Principal Balance"].map((key, index) => (
          <th
            key={index}
            style={{
              textAlign: "center",
              width: "50%",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {key}
          </th>
        ));
      }

      // console.log(">>>>>>>>>>>..",Object.keys(data[category][0]));

      let formattedHeaders = [];

      if (data[category][0] && typeof data[category][0] === "object") {
        formattedHeaders = Object.keys(data[category][0]).map((header) => {
          // Add space when capital letters are found in between a word
          return header === "REO"
            ? header
            : header
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        });

        // Filter out "Collection Account Detail" and "Prepayment and Default Rates"
        formattedHeaders = formattedHeaders.filter(
          (header) =>
            header !== "Collection Account Detail" &&
            header !== "Prepayment and Default Rates"
        );

        // Now you can safely work with formattedHeaders here
      } else {
        console.log("data[category][0] is undefined or not an object");
        // Handle the case when data[category][0] is undefined, null, or not an object
        // For example, you can provide a default value or show an error message.
      }

      return formattedHeaders.map((key, index) => (
        <th
          key={index}
          style={{
            textAlign: "center",
            height: "2%",
            width: "10%",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {key}
        </th>
      ));
    };

    const generateHeadersAndRowsCollection = (dataArray, category) => {
      const tableContents = [];

      tableContents.push(
        <h4
          className={`sectiontitle ${
            this.state["selected Color"] !== undefined
              ? "sectiontitle-active"
              : ""
          } headingTableCollection headingExcel`}
          style={{
            "--customColor": this.state["selected Color"] || "initial",
            color:
              this.state["selected Color"] === undefined
                ? "initial"
                : this.state["selected Color"],
          }}
          id={category}
        >
          {category === category.toUpperCase()
            ? category
            : category.replace(/([A-Z])/g, " $1").toUpperCase()}
        </h4>
      );

      for (let i = 0; i < dataArray.length; i++) {
        const headers = Object.keys(dataArray[i][0]);
        const formattedHeaders = headers.map((header) => {
          // Add space when capital letters are found in between a word
          return header.replace(/([a-z])([A-Z])/g, "$1 $2");
        });
        // console.log("formattedHeaders", formattedHeaders);

        const rows = dataArray[i].map((item, rowIndex) => (
          <tr key={rowIndex}>
            {Object.values(item).map((value, index) => {
              const isNumberOrPercentage =
                typeof value === "string" &&
                (value.endsWith("%") ||
                  /^-?\d+(\.\d+)?([eE][-+]?\d+)?$/.test(value)); // Updated regex to consider negative numbers
              const textAlign = isNumberOrPercentage ? "right" : "left";

              return (
                <td key={index} style={{ textAlign, width: "10%" }}>
                  <NumberComp5
                    value={value}
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  />
                </td>
              );
            })}
          </tr>
        ));

        tableContents.push(
          <table
            key={`table-${i}`}
            className="table table-bordered DownloadCollection"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                {formattedHeaders.map((header, headerIndex) => (
                  <th
                    key={headerIndex}
                    style={{
                      textAlign: "center",
                      width: "10%",
                      height: "2%",
                      backgroundColor:
                        this.state["selected Color"] === undefined
                          ? null
                          : this.state["selected Color"],
                    }}
                  >
                    {header
                      .replace(/([a-z])([A-Z])/g, "$1 $2")
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        );
      }

      return <div>{tableContents}</div>;
    };

    const generateHeadersAndRows = (dataArray, category) => {
      const tableContents = [];

      // console.log(">>>>>>>>>>>>>>>>", dataArray, category);

      tableContents.push(
        <h4
          className={`sectiontitle ${
            this.state["selected Color"] !== undefined
              ? "sectiontitle-active"
              : ""
          } headingTableCPR headingExcel`}
          style={{
            "--customColor": this.state["selected Color"] || "initial",
            color:
              this.state["selected Color"] === undefined
                ? "initial"
                : this.state["selected Color"],
          }}
          id={category}
        >
          {category === category.toUpperCase()
            ? category
            : category.replace(/([A-Z])/g, " $1").toUpperCase()}
        </h4>
      );
      for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i].length === 1) {
          const obj = dataArray[i][0];
          const header1 = Object.keys(obj)[0];
          const columns = Object.keys(obj).slice(1);
          const columns1 = Object.values(obj).slice(1);
          // console.log(">>>>>>>>>>>>>>>", header1);
          const rows = columns.map((column, index) => (
            <tr key={index}>
              <td
                style={{
                  textAlign: "left",
                  width: "10%",
                  height: "2%",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {column}
              </td>
              <td
                style={{
                  textAlign: "right",
                  width: "10%",
                  height: "2%",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <NumberComp5
                  value={columns1[index]}
                  style={{ fontFamily: "Poppins, sans-serif" }}
                />
              </td>
            </tr>
          ));

          tableContents.push(
            <>
              <table
                key={`table-${i}`}
                className="table table-bordered DownloadCPR"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        textAlign: "center",
                        width: "10%",
                        height: "2%",
                        fontFamily: "Poppins, sans-serif",
                        backgroundColor:
                          this.state["selected Color"] === undefined
                            ? null
                            : this.state["selected Color"],
                      }}
                      colSpan="2"
                    >
                      {header1}
                    </th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </>
          );
        }
      }

      for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i].length > 1) {
          const transformedArray = dataArray.map(function (subArray) {
            const headerRow = subArray[0];
            const transformedSubArray = subArray.slice(1).map(function (item) {
              const transformedItem = {};
              Object.keys(item).forEach(function (key) {
                transformedItem[headerRow[key]] = item[key];
              });
              return transformedItem;
            });
            return transformedSubArray; // Return only the transformed data without the header
          });
          const headers = Object.keys(transformedArray[i][0]);
          // console.log("???????????????????????", headers);
          const formattedHeaders = headers.map((header) => {
            // Add space when capital letters are found in between a word
            return header.replace(/([a-z])([A-Z])/g, "$1 $2");
          });

          const rows = transformedArray[i].map((item, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {Object.values(item).map((value, index) => {
                  const isNumberOrPercentage =
                    typeof value === "string" &&
                    (value.endsWith("%") ||
                      /^-?\d+(\.\d+)?([eE][-+]?\d+)?$/.test(value)); // Updated regex to consider negative numbers
                  const textAlign = isNumberOrPercentage ? "right" : "left";

                  return (
                    <td
                      key={index}
                      style={{
                        textAlign,
                        width: "10%",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <NumberComp5
                        value={value}
                        style={{ fontFamily: "Poppins, sans-serif" }}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          });

          tableContents.push(
            <table
              key={`table-${i}`}
              className="table table-bordered DownloadCPR"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  {formattedHeaders.map((header, headerIndex) => (
                    <th
                      key={headerIndex}
                      style={{
                        textAlign: "center",
                        width: "10%",
                        height: "2%",
                        fontFamily: "Poppins, sans-serif",
                        backgroundColor:
                          this.state["selected Color"] === undefined
                            ? null
                            : this.state["selected Color"],
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          );
        }
      }

      // console.log("tableContents", tableContents);

      [tableContents[2], tableContents[1]] = [
        tableContents[1],
        tableContents[2],
      ];

      return <div>{tableContents}</div>;
    };

    const generateRows = (arr, category) => {
      // console.log("category", category, arr);
      return arr.map((item, rowIndex) => (
        <tr key={rowIndex}>
          {Object.keys(item).map((key, index) => {
            const value = item[key];
            const isNumberOrPercentage =
              typeof value === "string" &&
              (value.endsWith("%") ||
                /^-?\d+(\.\d+)?([eE][-+]?\d+)?$/.test(value)); // Allow negative numbers
            const textAlign =
              key === "Cusip" || key === "Loan Count"
                ? "center"
                : isNumberOrPercentage
                ? "right"
                : "left";

            const dontConvert =
              key === "Loan Count" ||
              key === "LoanID" ||
              key === "Day Count Method" ||
              key === "Accrual Days"; // Check if key is "Loan Count"

            // const dontConvertInterest = key === "Interest Rate";

            return (
              <td
                key={index}
                style={{
                  textAlign,
                  width: "10%",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {category === "Factors Per $1,000" ? (
                  <NumberComp6
                    value={value}
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  />
                ) : dontConvert ? (
                  value
                ) : (
                  <NumberComp5
                    value={value}
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  />
                )}
              </td>
            );
          })}
        </tr>
      ));
    };

    return Object.keys(this.state.data).map((category, index) => (
      <div key={index} className="wrapper-pdf-container allTableExcel">
        {category === "Collection Account Detail" ||
        category === "Prepayment and Default Rates" ? null : (
          <h4
            className={`sectiontitle ${
              this.state["selected Color"] !== undefined
                ? "sectiontitle-active"
                : ""
            } headingTable headingExcel`}
            style={{
              "--customColor": this.state["selected Color"] || "initial",
              color:
                this.state["selected Color"] === undefined
                  ? "initial"
                  : this.state["selected Color"],
            }}
            id={category}
          >
            {category === category.toUpperCase()
              ? category
              : category.replace(/([A-Z])/g, " $1").toUpperCase()}
          </h4>
        )}

        {this.state.data[category].length === 0 ? (
          category === "Collection Account Detail" ||
          category === "Prepayment and Default Rates" ? null : (
            <table className="table table-bordered Download">
              <thead className="wrapper-pdf-container table.table.table-bordered thead">
                {category !== "Collection Account Detail" &&
                  category !== "Prepayment and Default Rates" && (
                    <tr
                      style={{
                        backgroundColor:
                          this.state["selected Color"] === undefined
                            ? null
                            : this.state["selected Color"],
                      }}
                    >
                      {generateHeaders(category)}
                    </tr>
                  )}
              </thead>
              <tbody>
                <tr>
                  <td colSpan="3">No data available for this category.</td>
                </tr>
              </tbody>
            </table>
          )
        ) : ["Prepayment and Default Rates"].includes(category) ? (
          <div className="wrapper-pdf-container">
            {generateHeadersAndRows(data[category], category)}
          </div>
        ) : ["Collection Account Detail"].includes(category) ? (
          <div className="wrapper-pdf-container">
            {generateHeadersAndRowsCollection(data[category], category)}
          </div>
        ) : (
          <div className="wrapper-pdf-container">
            {category === "Collection Account Detail" ||
            category === "Prepayment and Default Rates" ? null : (
              <table className="table table-bordered Download">
                <thead className="wrapper-pdf-container table.table.table-bordered thead">
                  {category !== "Collection Account Detail" &&
                    category !== "Prepayment and Default Rates" && (
                      <tr
                        style={{
                          backgroundColor:
                            this.state["selected Color"] === undefined
                              ? null
                              : this.state["selected Color"],
                        }}
                      >
                        {generateHeaders(category)}
                      </tr>
                    )}
                </thead>
                {category !== "Collection Account Detail" &&
                  category !== "Prepayment and Default Rates" && (
                    <tbody>{generateRows(data[category], category)}</tbody>
                  )}
              </table>
            )}
          </div>
        )}
      </div>
    ));
  }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            borderBottom: "none !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish, sans-serif !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish, sans-serif !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },

        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
      },
    });

  ViewReports = (value, tableData) => {
    console.log("value: ", value, tableData);
    if (tableData.rowIndex === 0) {
      window.location.assign("/admin/viewInvestorReport");
    } else if (tableData.rowIndex === 1) {
      window.location.assign("/admin/viewBDBReport");
    }
  };

  onOpenModal2 = (value, editValue) => {
    console.log("value: ", value, editValue);
    console.log(this.state.formData1);
    console.log("edit fees modal");
    // console.log('rateChange',editValue);
    if (this.state.ViewStatus === false) {
      const rateChange =
        editValue[2] !== "" ? editValue[2].replace(/%?/g, "") : editValue[2];
      this.setState(
        {
          EditPreviousName: value,
          open3: true,
          open2: false,
          formData1: {
            Name: value,
            LastModified: editValue[0],
          },
        },
        () => console.log(this.state.ViewStatus, this.state.formData1.Amount)
      );
    } else {
      this.setState(
        {
          open3: true,
          open2: false,
          ViewStatus: false,
          formData1: {
            ...this.state.formData1,
            Rate: this.state.formData1.Rate.replace(/%?/g, ""),
            Amount: this.state.formData1.Amount,
          },
        },
        () => console.log(this.state.ViewStatus)
      );
    }
  };

  onOpenModal3 = (value, deleteValue) => {
    console.log("value: ", value, deleteValue);
    console.log("delete fees modal");
    this.setState({
      open4: true,
      deleteRowVal: {
        Name: value,
        LastModified: deleteValue[0],
      },
    });
  };

  handleDealNameChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("dealname", newValue);
    this.setState(
      { DealName: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.DealName);
        await this.getAllPaymentDatesByDealName();

        if (this.state.isLoansProcessed === "No") {
          window.location.assign("/admin/general_recurring");
        }

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isDisable: false, publishVersion: Version, Version: "WIP" },
            async () => {
              await this.ViewConsolidatedReport();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            this.setState({
              isDisable: this.state.isDisable,
              publishVersion: Version,
            });
            this.ViewConsolidatedReport();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              { isDisable: false, publishVersion: Version, Version: "WIP" },
              async () => {
                await this.ViewConsolidatedReport();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  getAllPaymentDatesByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllPaymentDatesByDealName(data);

    console.log("getAllPaymentDatesByDealName", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem(
        "nextpaymentdate",
        APIResponse.data.PaymentDates[0]
      );
      sessionStorage.setItem(
        "getDashboardDates",
        JSON.stringify(APIResponse.data.PaymentDates)
      );
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("nextpaymentdate", newValue);
    this.setState(
      { NextPaymentDate: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.NextPaymentDate);
        await this.getAllVersionsByPaymentDate();

        if (this.state.isLoansProcessed === "No") {
          window.location.assign("/admin/general_recurring");
        }

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isDisable: false, publishVersion: Version, Version: "WIP" },
            async () => {
              await this.ViewConsolidatedReport();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            this.setState({
              isDisable: this.state.isDisable,
              publishVersion: Version,
            });
            this.ViewConsolidatedReport();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              { isDisable: false, publishVersion: Version, Version: "WIP" },
              async () => {
                await this.ViewConsolidatedReport();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the Version state with the selected value
    sessionStorage.setItem("version", newValue);
    this.setState(
      { Version: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.Version);

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isDisable: false, publishVersion: Version, Version: "WIP" },
            async () => {
              await this.ViewConsolidatedReport();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            this.setState({
              isDisable: this.state.isDisable,
              publishVersion: sessionStorage.getItem("version"),
            });
            this.ViewConsolidatedReport();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              { isDisable: false, publishVersion: Version, Version: "WIP" },
              async () => {
                await this.ViewConsolidatedReport();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  scrollToTop = (position) => {
    window.scrollTo({
      top: position,
      behavior: "smooth",
    });
  };

  render() {
    const { classes } = this.props;
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const columns = [
      {
        name: "Name",
        label: "Name",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "LastModified",
        label: "Last Modified",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Name",
        label: "Actions",
        options: {
          filter: true,
          sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                textAlign: "center",
                paddingTop: "5px",
                paddingLeft: "2rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div
                  className=""
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "2rem",
                  }}
                >
                  {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={this.onOpenModal1.bind(this)}> */}
                  <button
                    className="popupbutton1"
                    onClick={() => this.ViewReports(value, tableMeta)}
                  >
                    View
                  </button>
                  {/* <button
                      className="popupbutton1"
                      onClick={() =>
                        this.onOpenModal2(value, tableMeta.rowData.splice(1, 4))
                      }
                    >
                      /Edit
                    </button> */}
                  {/* <button
                      className="popupbuttons1"
                      onClick={() =>
                        this.onOpenModal3(
                          value,
                          tableMeta.rowData.splice(1, 4)
                        )
                      }
                    >
                      Delete
                    </button> */}
                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },
    ];
    const sortedDeals = [...this.state.getDashboardDeals].sort();

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Reporting"} />
          {this.state.screenloader === true ? (
            <LinearLoader />
          ) : (
            <div className="content">
              <div className="header">
                <Header></Header>
              </div>
              <div className="page-contentofpool1">
                <div className="row1">
                  <div
                    className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1.5rem",
                      width: "90rem",
                      position: "relative",
                      left: "0.2rem",
                    }}
                  >
                    <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                      {/* <KeyboardBackspaceIcon
                        onClick={this.goBackToDashboard}
                        className="left-arrow-muis1 left-arrow-servicer"
                      ></KeyboardBackspaceIcon> */}
                      <h3 className="headerdashboard">Reporting</h3>
                    </div>
                  </div>
                </div>

                <div className="row1 css-recurring move-dropdowns-reporting">
                  <div className="recurring_details_new_Reporting">
                    <div>
                      <label className="dealInfo">Deal Name </label>
                      {/* <h6 className="dealInfo1">{this.state.DealName}</h6> */}
                      <div>
                        <select
                          className="input-select-general-new1-deal"
                          value={this.state?.DealName}
                          onChange={this.handleDealNameChange}
                        >
                          {sortedDeals.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="dealInfo">Payment Date </label>
                      {/* <h6 className="dealInfo1">
                          {this.state?.NextPaymentDate}
                        </h6> */}
                      <div>
                        <select
                          className="input-select-general-new1"
                          value={this.state?.NextPaymentDate}
                          onChange={this.handlePaymentDateChange}
                        >
                          {this.state.getDashboardDates.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="dealInfo">Version </label>
                      {/* <h6 className="dealInfo1">{this.state?.Version}</h6> */}
                      <div>
                        <select
                          className="input-select-general-new1"
                          value={
                            this.state.Version === "WIP"
                              ? sessionStorage.getItem("version")
                              : this.state.Version
                          }
                          onChange={this.handleVersionChange}
                        >
                          {this.state.getDashboardVersions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="buttonsverified uw-deal-details-button-container">
                    <OverlayTrigger
                      rootClose
                      trigger="click"
                      placement="bottom"
                      overlay={this.popoverBottom()}
                    >
                      <Button
                        variant="outlined"
                        onClick={() =>
                          this.setState((prevState) => ({
                            downArrow: !prevState.downArrow,
                          }))
                        }
                      >
                        Edit
                        {this.state.downArrow ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropUpIcon />
                        )}
                      </Button>
                    </OverlayTrigger>
                    {this.state.isDisable === true ||
                    this.state.isLoansProcessed === "No" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.CalculatePrePayments}
                        disabled
                      >
                        Calculate
                        {this.state.formLoader === true ? (
                          <CircularProgress size="22px" color="primary" />
                        ) : (
                          ""
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={
                          sessionStorage.getItem("isSecuritisation") !==
                          "Securitisation"
                            ? this.onOpenModalBorrow
                            : this.CalculatePrePayments
                        }
                      >
                        Calculate
                        {this.state.formLoader === true ? (
                          <CircularProgress size="22px" color="primary" />
                        ) : (
                          ""
                        )}
                      </Button>
                    )}
                  </div> */}
                </div>

                <div className="row row1 movement-tabs-reporting">
                  <div>
                    {/* <div className="tablechangebutton"> */}
                    <div className="tablechangebuttonloans">
                      {/* <button
                        type="button"
                        onClick={() => this.handleClickGeneral()}
                        className={
                          this.state.activeInsights1 == true
                            ? "issuerDashboard-table-top-button-insights-active"
                            : "issuerDashboard-table-top-button-insights"
                        }
                      >
                        General
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickTranches()}
                        className={
                          this.state.activeInsights2 == true
                            ? "issuerDashboard-table-top-button-workbench-active"
                            : "issuerDashboard-table-top-button-workbench"
                        }
                      >
                        Tranches
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickFees()}
                        className={
                          this.state.activeInsights3 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Fees
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickExpenses()}
                        className={
                          this.state.activeInsights4 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Expenses
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickAccounts()}
                        className={
                          this.state.activeInsights5 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Accounts
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickTests()}
                        className={
                          this.state.activeInsights6 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Tests
                      </button>
                      {this.state.isSecuritisation !== "Securitisation" ? (
                        <button
                          type="button"
                          onClick={() => this.handleBorrowingBase()}
                          className={
                            this.state.activeInsightsBB == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          Borrowing Base
                        </button>
                      ) : null}
                      <button
                        type="button"
                        onClick={() => this.handleClickVariables()}
                        className={
                          this.state.activeInsights7 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Variables
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickPaymentRules()}
                        className={
                          this.state.activeInsights8 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Payment Rules
                      </button> */}
                      <button
                        type="button"
                        onClick={() => this.handleClickCollateral()}
                        className={
                          this.state.activeInsights9 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Collateral
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickLoanTape()}
                        className={
                          this.state.activeInsights10 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Loan Tape
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickConsolidated()}
                        className={
                          this.state.activeInsights11 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Consolidated
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickFiles()}
                        className={
                          this.state.activeInsights12 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Files
                      </button>
                    </div>
                  </div>
                  {/* <div className="recurring_details">
                      <div>
                        <label className="dealInfo">Deal Name </label>
                        <h6 className="dealInfo1">{this.state.DealName}</h6>
                      </div>
                      <div>
                        <label className="dealInfo">Payment Date </label>
                        <h6 className="dealInfo1">{this.state?.NextPaymentDate}</h6>
                      </div>
                    </div> */}
                </div>

                <div className="row row1 movement-header-reporting">
                  <div className="investor-heading-container">
                    <h1 className="headerdashboard1">Consolidated Details</h1>
                  </div>
                  <div className="col-12 col-sm-6 col-md-5 hellocard">
                    <div className="buttonsverified uw-deal-details-button-container">
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="bottom"
                        overlay={this.popoverBottomDownload()}
                        onExited={this.handleOverlayHide}
                      >
                        {this.state.isLoansProcessed === "No" ? (
                          <Button
                            variant="outlined"
                            onClick={() =>
                              this.setState((prevState) => ({
                                downArrow: !prevState.downArrow,
                              }))
                            }
                            disabled
                          >
                            Download
                            {this.state.downArrow ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )}
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            onClick={this.handleButtonClick}
                          >
                            Download
                            {this.state.downArrow ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )}
                          </Button>
                        )}
                      </OverlayTrigger>
                      {this.state.publishData === "Disable" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.PublishReport}
                          disabled
                        >
                          Publish
                          {this.state.formLoader1 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.PublishReport}
                        >
                          Publish
                          {this.state.formLoader1 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      )}
                      {/* <Button
                      variant="contained"
                      color="primary"
                      //   onClick={this.onOpenModal1}
                    >
                      View Report
                    </Button> */}
                    </div>
                  </div>
                </div>

                <React.Fragment>
                  <div className="css-consolidated movement-table-reporting">
                    <div className="page-first" id="generatePdf">
                      <div className="wrapper-pdf">
                        <div className="consolidated_details_new">
                          <div className="consolidated_row">
                            <div className="image_details">
                              <img
                                src={
                                  this.state["Image Upload"] === ""
                                    ? WSFS_Logo
                                    : this.state["Image Upload"]
                                }
                                alt="WSFS_Logo"
                                className="intain_img"
                              />
                              {/* <h6>Intain Technologies Pvt. Ltd.</h6> */}
                            </div>
                            <div className="rightSideDealContent">
                              <div>
                                <h4 className="dealConso">
                                  {this.state.DealName}
                                </h4>
                              </div>
                              <div>
                                <label className="dealInfo">
                                  Distribution Date :
                                  {
                                    this.state.newModifiedData[
                                      "Distribution Date"
                                    ]
                                  }
                                </label>
                              </div>
                              <div>
                                <label className="dealInfo">
                                  {this.state.newModifiedData["Asset Class"]}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <br />
                        <br />
                        <br />
                        <div className="consolidated_details_new_details">
                          <div className="consolidated_details_new_left">
                            <ul className="linkToTable">
                              <li>
                                <h4 className="heading_tableIndex">
                                  Table Of Contents
                                </h4>
                                <a href="#Certificate Payment Detail" id="TOC">
                                  1. Certificate Payment Report
                                </a>
                              </li>
                              <li>
                                <a href="#Collection Account Detail" id="TOC">
                                  2. Collection Account Report
                                </a>
                              </li>
                              <li>
                                <a href="#STRUCTURAL FEATURES" id="TOC">
                                  3. Credit Enhancement Report
                                </a>
                              </li>
                              <li>
                                <a href="#COLLATERAL" id="TOC">
                                  4. Collateral Report
                                </a>
                              </li>
                              <li>
                                <a href="#DEAL EVENTS" id="TOC">
                                  5. Deal Events Report
                                </a>
                              </li>
                              <li>
                                <a href="#Collateral Performance" id="TOC">
                                  6. Delinquency Report
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#Prepayment and Default Rates"
                                  id="TOC"
                                >
                                  7. Prepayments and Default Rates Report
                                </a>
                              </li>
                              <li>
                                <a href="#Priority of Payments" id="TOC">
                                  8. Priorties of Payments Report
                                </a>
                              </li>
                              <li>
                                <a href="#LoanDetails - Paid In Full" id="TOC">
                                  9. Loan Details Report
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="consolidated_details_new_right ExcelRight">
                            <div>
                              <h4 className="dealInfo" id="ep">
                                External Parties
                              </h4>
                              <div>
                                <label className="reduce_gap" id="EP">
                                  Issuer
                                  {this.state.newModifiedData[
                                    "External Parties"
                                  ] &&
                                  this.state.newModifiedData[
                                    "External Parties"
                                  ][0] ? (
                                    <h6 className="reduce_gap_Issuer">
                                      {this.state.newModifiedData[
                                        "External Parties"
                                      ][0]["Issuer"]
                                        ? this.state.newModifiedData[
                                            "External Parties"
                                          ][0]["Issuer"]
                                        : "Loading..."}
                                    </h6>
                                  ) : (
                                    <h6 className="reduce_gap">Loading...</h6>
                                  )}
                                </label>
                              </div>

                              <div className="right_service">
                                <div className="service_common">
                                  <label
                                    className="reduce_gap_servicer"
                                    id="EP"
                                  >
                                    Servicer
                                    {this.state.newModifiedData[
                                      "External Parties"
                                    ] &&
                                    this.state.newModifiedData[
                                      "External Parties"
                                    ][0] ? (
                                      <div className="reduce_gap_servicer">
                                        {this.state.newModifiedData[
                                          "External Parties"
                                        ][0]["Servicer"] ? (
                                          this.state.newModifiedData[
                                            "External Parties"
                                          ][0]["Servicer"].map(
                                            (servicer, index) => (
                                              <h6 key={index}>{servicer}</h6>
                                            )
                                          )
                                        ) : (
                                          <h6 className="reduce_gap">
                                            Loading...
                                          </h6>
                                        )}
                                      </div>
                                    ) : null}
                                  </label>
                                </div>

                                <div className="seperator"></div>
                                <div className="service_common">
                                  {this.state.newModifiedData[
                                    "External Parties"
                                  ] &&
                                  this.state.newModifiedData[
                                    "External Parties"
                                  ][0] &&
                                  this.state.newModifiedData[
                                    "External Parties"
                                  ][0]["Originator"] ? (
                                    <label
                                      className="reduce_gap_servicer"
                                      id="EP"
                                    >
                                      Originator
                                      <div className="reduce_gap_servicer">
                                        {this.state.newModifiedData[
                                          "External Parties"
                                        ][0]["Originator"].map(
                                          (originator, index) => (
                                            <h6 key={index}>{originator}</h6>
                                          )
                                        )}
                                      </div>
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div>
                              <h4 className="dealInfo" id="gd">
                                General Details
                              </h4>
                              <div className="general_align">
                                <div className="general_consolidated">
                                  <label className="reduce_gap" id="GD">
                                    Cut-Off Date
                                    {this.state.newModifiedData[
                                      "General Details"
                                    ] &&
                                    this.state.newModifiedData[
                                      "General Details"
                                    ][0] ? (
                                      <h6 className="reduce_gap">
                                        {this.state.newModifiedData[
                                          "General Details"
                                        ][0]["Cut-Off Date"] === undefined ||
                                        this.state.newModifiedData[
                                          "General Details"
                                        ][0]["Cut-Off Date"] === ""
                                          ? "-"
                                          : this.state.newModifiedData[
                                              "General Details"
                                            ][0]["Cut-Off Date"]}
                                      </h6>
                                    ) : (
                                      <h6 className="reduce_gap">Loading...</h6>
                                    )}
                                  </label>

                                  <label className="reduce_gap" id="GDD">
                                    Payment Date
                                    {this.state.newModifiedData[
                                      "General Details"
                                    ] &&
                                    this.state.newModifiedData[
                                      "General Details"
                                    ][0] ? (
                                      <h6 className="reduce_gap">
                                        {
                                          this.state.newModifiedData[
                                            "General Details"
                                          ][0]["Payment Date"]
                                        }
                                      </h6>
                                    ) : (
                                      <h6 className="reduce_gap">Loading...</h6>
                                    )}
                                  </label>

                                  {/* <label className="reduce_gap">
                                    Record Date
                                    {this.state.newModifiedData[
                                      "General Details"
                                    ] &&
                                    this.state.newModifiedData[
                                      "General Details"
                                    ][0] ? (
                                      <h6 className="reduce_gap">
                                        {
                                          this.state.newModifiedData[
                                            "General Details"
                                          ][0]["Record Date"] === undefined ? "-" :  this.state.newModifiedData[
                                            "General Details"
                                          ][0]["Record Date"]
                                        }
                                      </h6>
                                    ) : (
                                      <span>Loading...</span>
                                    )}
                                  </label> */}
                                </div>
                                <div className="general_consolidated">
                                  <label className="reduce_gap" id="GD">
                                    Closing Date
                                    {this.state.newModifiedData[
                                      "General Details"
                                    ] &&
                                    this.state.newModifiedData[
                                      "General Details"
                                    ][0] ? (
                                      <h6 className="reduce_gap">
                                        {
                                          this.state.newModifiedData[
                                            "General Details"
                                          ][0]["Closing Date"]
                                        }
                                      </h6>
                                    ) : (
                                      <h6 className="reduce_gap">Loading...</h6>
                                    )}
                                  </label>

                                  <label className="reduce_gap" id="GDD">
                                    Next Payment Date
                                    {this.state.newModifiedData[
                                      "General Details"
                                    ] &&
                                    this.state.newModifiedData[
                                      "General Details"
                                    ][0] ? (
                                      <h6 className="reduce_gap">
                                        {
                                          this.state.newModifiedData[
                                            "General Details"
                                          ][0]["Next Payment Date"]
                                        }
                                      </h6>
                                    ) : (
                                      <h6 className="reduce_gap">Loading...</h6>
                                    )}
                                  </label>

                                  {/* <label className="reduce_gap">
                                    Determination Date
                                    {this.state.newModifiedData[
                                      "General Details"
                                    ] &&
                                    this.state.newModifiedData[
                                      "General Details"
                                    ][0] ? (
                                      <h6 className="reduce_gap">
                                        {
                                          this.state.newModifiedData[
                                            "General Details"
                                          ][0]["Determination Date"] === undefined ? "-" : this.state.newModifiedData[
                                            "General Details"
                                          ][0]["Determination Date"] 
                                        }
                                      </h6>
                                    ) : (
                                      <span>Loading...</span>
                                    )}
                                  </label> */}
                                </div>
                                <div className="general_consolidated">
                                  <label className="reduce_gap" id="GD">
                                    First Payment Date
                                    {this.state.newModifiedData[
                                      "General Details"
                                    ] &&
                                    this.state.newModifiedData[
                                      "General Details"
                                    ][0] ? (
                                      <h6 className="reduce_gap">
                                        {
                                          this.state.newModifiedData[
                                            "General Details"
                                          ][0]["First Payment Date"]
                                        }
                                      </h6>
                                    ) : (
                                      <h6 className="reduce_gap">Loading...</h6>
                                    )}
                                  </label>

                                  <label className="reduce_gap" id="GDD">
                                    Payment Frequency
                                    {this.state.newModifiedData[
                                      "General Details"
                                    ] &&
                                    this.state.newModifiedData[
                                      "General Details"
                                    ][0] ? (
                                      <h6 className="reduce_gap" id="frequency">
                                        {
                                          this.state.newModifiedData[
                                            "General Details"
                                          ][0]["Payment Frequency"]
                                        }
                                      </h6>
                                    ) : (
                                      <h6 className="reduce_gap">Loading...</h6>
                                    )}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div>
                              <h4 className="dealInfo">Contact Information</h4>
                              <div>
                                <label className="reduce_gap_contact" id="CN">
                                  {/* <strong> Devon C.A. </strong> (Reverdito
                                  Relationship Manager) */}
                                  {/* <h6 className="reduce_gap_contact">
                                    {this.state.NextPaymentDate}
                                  </h6> */}
                                  {/* Jennifer A. Luce */}
                                </label>
                                <label className="reduce_gap_contact" id="CN">
                                  Relationship Manager
                                </label>
                                <label className="reduce_gap_contact" id="CN">
                                  Address :
                                  {/* 500 Delaware Ave, 11th
                                  Floor,Wilmington, DE 19801 */}
                                </label>
                              </div>
                              <div className="contact_gap">
                                <label className="reduce_gap_contact" id="CI">
                                  Email ID :{/* jluce@wsfsbank.com */}
                                </label>
                                <label className="reduce_gap_contact">
                                  Phone :{/* (415) 555-0132 */}
                                </label>
                              </div>
                              <div className="contact_gap">
                                <label className="reduce_gap_contact" id="CI">
                                  Website :{/* www.wsfsabsreporting.com */}
                                </label>
                                <label className="reduce_gap_contact">
                                  Fax :{/* +1-907-555-1234 */}
                                </label>
                              </div>
                              <div className="contact_gap" hidden>
                                <label className="reduce_gap_contact" id="DIS">
                                  {`In the preparation of this report, Wilmington Savings Fund Society, FSB is conclusively relying on information provided to it by third parties, including the Servicer and other parties to the transaction.
                            Such third parties are believed to be reliable, but the information is not independently verified and no representation is made as to its accuracy, suitability, or completeness.
                                Descriptions for the fields included in the report are provided in the issue’s governing documents. Additional information is available via the contact information above.`}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="table-container">
                      {this.generateIndividualTables()}
                    </div>
                    <button
                      onClick={() => this.scrollToTop(600)}
                      className="scroll-to-top-button"
                      style={{
                        "--customColor":
                          this.state["selected Color"] || "initial",
                      }}
                    >
                      <span className="arrow">&#10148;</span>
                    </button>
                  </div>
                  <>
                    <div className="btn_move">
                      <div className="btn_prev" onClick={this.showPrev}>
                        Previous
                      </div>
                      <button className="btn_next" onClick={this.showNext}>
                        Next
                      </button>
                    </div>
                  </>
                </React.Fragment>

                <>
                  <ReactModal
                    isOpen={this.state.open3}
                    onRequestClose={this.onCloseModal2}
                    contentLabel="Minimal Modal Example"
                    style={customStylesauto}
                  >
                    <React.Fragment>
                      <div className="modalPopup">
                        <h2>Edit Report</h2>
                        <button
                          type="button"
                          className="closePopup"
                          style={{ minWidth: "30px" }}
                          onClick={this.onCloseModal2}
                        >
                          <CloseIcon></CloseIcon>{" "}
                        </button>

                        {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                        <div className="modalshiftcontent">
                          <form
                            className="form-container"
                            onSubmit={this.EditSubmit}
                          >
                            <div className="input-container">
                              <label className="label"> Name</label>
                              <input
                                required
                                placeholder="Type here"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      Name: e.target.value,
                                    },
                                  });
                                }}
                                value={this.state.formData1.Name}
                              />
                            </div>

                            <div className="input-container">
                              <label className="label">Last Modified</label>
                              <input
                                placeholder="Type here"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      LastModified: e.target.value,
                                    },
                                  });
                                }}
                                value={this.state.formData1.LastModified}
                              />
                            </div>

                            <div className="modalsubmit">
                              <div className="submitbuttonbg">
                                <div className="row">
                                  <div className="row justify-content-end1">
                                    <button
                                      type="button"
                                      className="popupbutton22"
                                      onClick={this.onCloseModal2}
                                    >
                                      {" "}
                                      Cancel{" "}
                                    </button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      type="submit"
                                    >
                                      Save
                                      {this.state.formLoader === true ? (
                                        <CircularProgress
                                          size="22px"
                                          color="primary"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>

                  <ReactModal
                    isOpen={this.state.openModalPayment}
                    contentLabel="Minimal Modal Example"
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "fixed", // Use fixed positioning for the overlay
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                      },
                      content: {
                        position: "absolute",
                        background: "transparent", // Make the modal content transparent
                        border: "none", // Optional: Remove any borders
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        overflow: "hidden",
                      },
                    }}
                  >
                    <React.Fragment>
                      <img
                        src={BeanEater}
                        style={{
                          width: "8vw",
                          height: "9vh",
                          backgroundColor: "transparent",
                        }}
                      />
                      <h3 className="dot-loader">Loading.Please wait</h3>
                    </React.Fragment>
                  </ReactModal>

                  <div id="modal1">
                    <ReactModal
                      isOpen={this.state.open4}
                      onRequestClose={this.onCloseModal3}
                      contentLabel="Minimal Modal Example"
                      style={customStylesautosmallmodal1}
                    >
                      <React.Fragment>
                        <div className="modalPopup">
                          <h3 className="popupheading">
                            Are you sure, you want to Delete this Fee "
                            {this.state.deleteRowVal.Name}"?
                          </h3>

                          {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                          <div className="modalshiftcontent">
                            <div className="modalsubmit">
                              <div className="submitbuttonbg">
                                <div className="row">
                                  <div className="row justify-content-end11">
                                    <button
                                      type="button"
                                      className="popupbutton1"
                                      onClick={this.onCloseModal3}
                                    >
                                      No
                                    </button>

                                    <Button
                                      variant="contained"
                                      color="primary"
                                      type="submit"
                                      onClick={this.dealCreationDelete}
                                    >
                                      Yes, Delete it
                                      {this.state.formLoader === true ? (
                                        <CircularProgress
                                          size="25px"
                                          color="primary"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.getLoansLoader === false ? (
                              ""
                            ) : (
                              <FormLoader></FormLoader>
                            )}
                            {/* {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Processor addded Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}
                          </div>
                        </div>
                      </React.Fragment>
                    </ReactModal>

                    <div id="modal1">
                      <ReactModal
                        isOpen={this.state.isBorrow}
                        onRequestClose={this.onCloseModalBorrow}
                        contentLabel="Minimal Modal Example"
                        style={customStylesautosmallmodalpopupBorrow}
                      >
                        <React.Fragment>
                          <div className="modalPopup">
                            <h3 className="popupheading">
                              Do you want to run Payment Waterfall?
                            </h3>

                            {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                            <div className="modalshiftcontent">
                              <div className="modalsubmit">
                                <div className="submitbuttonbg">
                                  <div className="row">
                                    <div className="row justify-content-start11">
                                      <button
                                        type="button"
                                        className="popupbutton1"
                                        onClick={this.onCloseModalBorrow}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                    <div className="row justify-content-end11">
                                      <button
                                        type="button"
                                        className="popupbutton1"
                                        onClick={this.checkBorrowBaseNo}
                                      >
                                        No
                                      </button>

                                      <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={this.checkBorrowBaseYes}
                                      >
                                        Yes
                                        {/* {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="25px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )} */}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      </ReactModal>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(ConsolidatedRecurring);