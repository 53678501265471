import React, { useState, useEffect, useRef } from "react";
import { Table, Button } from "antd";
import Plot from "react-plotly.js";
import "./MonthlyDash.css";

function MonthlyDash() {
  const [plotData, setPlotData] = useState({
    property_under_contract_time: { data: [], layout: {} },
    property_under_contract_wa_time: { data: [], layout: {} },
    property_under_renovation_time: { data: [], layout: {} },
  });
  const [summaryTrancheData, setSummaryTrancheData] = useState([]);
  const [showChart, setShowChart] = useState(true);
  const plotContainerRef = useRef(null);

  const contractColumns = [
    {
      title: "Advance Rate Stepdown Trigger",
      dataIndex: "Advance Rate Stepdown Trigger",
      key: "advanceRateStepdownTrigger",
    },
    {
      title: "Month",
      dataIndex: "Month",
      key: "month",
    },
    {
      title: "Properties Under Contract ($)",
      dataIndex: "Properties Under Contract $",
      key: "propertiesUnderContractDollar",
      render: (value) => (value ? `$${value.toLocaleString()}` : "N/A"),
    },
    {
      title: "Properties Under Contract (%)",
      dataIndex: "Properties under Contract %",
      key: "propertiesUnderContractPercent",
      render: (value) => `${(value * 100).toFixed(2)}%`,
    },
    {
      title: "Weighted Average Profit (%)",
      dataIndex:
        "Weighted Average profit for properties that went under contract",
      key: "weightedAverageProfit",
      render: (value) => `${(value * 100).toFixed(2)}%`,
    },
    {
      title: "Properties in Renovation ($)",
      dataIndex: "Properties in Renovation $",
      key: "propertiesInRenovationDollar",
      render: (value) => (value ? `$${value.toLocaleString()}` : "N/A"),
    },
    {
      title: "Properties in Renovation (%)",
      dataIndex: "Properties in Renovation %",
      key: "propertiesInRenovationPercent",
      render: (value) => `${(value * 100).toFixed(2)}%`,
    },
  ];

  const waColumns = [
    {
      title: "Advance Rate Stepdown Trigger",
      dataIndex: "Advance Rate Stepdown Trigger",
      key: "advanceRateStepdownTrigger",
    },
    {
      title: "Month",
      dataIndex: "Month",
      key: "month",
    },
    {
      title: "Weighted Average Profit (%)",
      dataIndex:
        "Weighted Average profit for properties that went under contract",
      key: "weightedAverageProfit",
      render: (value) => `${(value * 100).toFixed(2)}%`,
    },
  ];

  const renovationColumns = [
    {
      title: "Advance Rate Stepdown Trigger",
      dataIndex: "Advance Rate Stepdown Trigger",
      key: "advanceRateStepdownTrigger",
    },
    {
      title: "Month",
      dataIndex: "Month",
      key: "month",
    },
    {
      title: "Properties in Renovation ($)",
      dataIndex: "Properties in Renovation $",
      key: "propertiesInRenovationDollar",
      render: (value) => (value ? `$${value.toLocaleString()}` : "N/A"),
    },
    {
      title: "Properties in Renovation (%)",
      dataIndex: "Properties in Renovation %",
      key: "propertiesInRenovationPercent",
      render: (value) => `${(value * 100).toFixed(2)}%`,
    },
  ];

  useEffect(() => {
    let retries = 0;
    const maxRetries = 30; // Stop polling after 30 attempts (~30 seconds)

    const pollData = () => {
      const finalData = sessionStorage.getItem("finalDataMonthly");

      if (finalData) {
        try {
          const parsedData = JSON.parse(finalData);
          processFinalData(parsedData);
        } catch (error) {
          console.error("Error parsing session data:", error);
        }
      } else if (retries < maxRetries) {
        retries += 1;
        setTimeout(pollData, 1000); // Retry after 1 second
      } else {
        console.warn("Max retries reached. No data found in sessionStorage.");
      }
    };

    const processFinalData = (data) => {
      const parsePlotData = (plotData) => {
        let parsedData = { data: [], layout: {} };

        if (typeof plotData === "string") {
          parsedData = JSON.parse(plotData);
        } else if (typeof plotData === "object" && plotData !== null) {
          parsedData = plotData;
        }

        if (!parsedData.layout) {
          parsedData.layout = {};
        }

        parsedData.layout.width =
          plotContainerRef.current?.offsetWidth || window.innerWidth;
        parsedData.layout.autosize = true;

        return parsedData;
      };

      const property_under_contract_time = parsePlotData(
        data.property_under_contract_time
      );
      const property_under_contract_wa_time = parsePlotData(
        data.property_under_contract_wa_time
      );
      const property_under_renovation_time = parsePlotData(
        data.property_under_renovation_time
      );

      setPlotData({
        property_under_contract_time,
        property_under_contract_wa_time,
        property_under_renovation_time,
      });

      const contractTable = data.property_under_contract_table || [];
      const waTable = data.property_under_contract_wa_table || [];
      const renovationTable = data.property_under_renovation_table || [];

      const combinedData = contractTable.map((contractData) => {
        const waData = waTable.find((wa) => wa.Month === contractData.Month);
        const renovationData = renovationTable.find(
          (renovation) => renovation.Month === contractData.Month
        );

        return {
          ...contractData,
          "Weighted Average profit for properties that went under contract":
            waData
              ? waData[
                  "Weighted Average profit for properties that went under contract"
                ]
              : 0,
          "Properties in Renovation $": renovationData
            ? renovationData["Properties in Renovation $"]
            : null,
          "Properties in Renovation %": renovationData
            ? renovationData["Properties in Renovation %"]
            : 0,
        };
      });

      setSummaryTrancheData({
        contractData: combinedData,
        waTable,
        renovationTable,
      });
    };

    pollData();

    const handleResize = () => {
      setPlotData((prevPlotData) => ({
        property_under_contract_time: {
          ...prevPlotData.property_under_contract_time,
          layout: {
            ...prevPlotData.property_under_contract_time.layout,
            width: plotContainerRef.current?.offsetWidth || window.innerWidth,
          },
        },
        property_under_contract_wa_time: {
          ...prevPlotData.property_under_contract_wa_time,
          layout: {
            ...prevPlotData.property_under_contract_wa_time.layout,
            width: plotContainerRef.current?.offsetWidth || window.innerWidth,
          },
        },
        property_under_renovation_time: {
          ...prevPlotData.property_under_renovation_time,
          layout: {
            ...prevPlotData.property_under_renovation_time.layout,
            width: plotContainerRef.current?.offsetWidth || window.innerWidth,
          },
        },
      }));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="portfolioDash">
      <Button
        type="primary"
        onClick={() => setShowChart(!showChart)}
        className="toggle-button"
        style={{ background: "#004d40" }}
      >
        {showChart ? "Show Table" : "Show Chart"}
      </Button>
      <div
        className="chart-container-portfoilo"
        style={{ display: showChart ? "grid" : "block" }}
        ref={plotContainerRef}
      >
        {showChart ? (
          <div className="plot-borders-bar-monthly">
            <div className="plot-box">
              <Plot
                data={plotData.property_under_contract_time.data}
                layout={plotData.property_under_contract_time.layout}
                config={{
                  displaylogo: false,
                  modeBarButtonsToRemove: [
                    "pan2d",
                    "select2d",
                    "lasso2d",
                    "zoom",
                    "resetScale2d",
                  ],
                }}
                className="plot-inline"
              />
            </div>
            <div className="plot-box">
              <Plot
                data={plotData.property_under_contract_wa_time.data}
                layout={plotData.property_under_contract_wa_time.layout}
                config={{
                  displaylogo: false,
                  modeBarButtonsToRemove: [
                    "pan2d",
                    "select2d",
                    "lasso2d",
                    "zoom",
                    "resetScale2d",
                  ],
                }}
                className="plot-inline"
              />
            </div>
            <div className="plot-box">
              <Plot
                data={plotData.property_under_renovation_time.data}
                layout={plotData.property_under_renovation_time.layout}
                config={{
                  displaylogo: false,
                  modeBarButtonsToRemove: [
                    "pan2d",
                    "select2d",
                    "lasso2d",
                    "zoom",
                    "resetScale2d",
                  ],
                }}
                className="plot-inline"
              />
            </div>
          </div>
        ) : (
          <div className="workbench-table-container monthly-tables">
            <label className="tableHeaders">
              Property Under Contract Table
            </label>
            <div className="dash-table-alignments">
              <Table
                columns={contractColumns}
                className="performance-table"
                pagination={false}
                dataSource={summaryTrancheData.contractData}
                bordered={false}
                scroll={{ x: "max-content" }}
              />
            </div>
            <label className="tableHeaders">
              Property Under Contract WA Table
            </label>
            <div className="dash-table-alignments">
              <Table
                columns={waColumns}
                className="performance-table"
                pagination={false}
                dataSource={summaryTrancheData.waTable}
                bordered={false}
                scroll={{ x: "max-content" }}
              />
            </div>
            <label className="tableHeaders">
              Property Under Renovation Table
            </label>
            <div className="dash-table-alignments">
              <Table
                columns={renovationColumns}
                className="performance-table"
                pagination={false}
                dataSource={summaryTrancheData.renovationTable}
                bordered={false}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MonthlyDash;
